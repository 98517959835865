/* public-path Sets the cdn path of the chunks.  Must come first in this file */
import '~/common/public-path';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {MegaNav} from './MegaNav';

const container = document.getElementById('meganav');

evite.when(['react', 'site-libraries']).then(() => {
  if (container) {
    const root = createRoot(container);
    root.render(<MegaNav />);
  } else {
    console.warn('This page DOM is missing element with id="meganav"');
  }
});
