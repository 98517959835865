import React from 'react';
import {ResponsiveModal} from '~/common/_pb_components/organisms/ResponsiveModal';

export const ResetPasswordModal = ({isOpen, setIsOpen, handleClose}) => (
  <ResponsiveModal
    isOpen={isOpen}
    title="PASSWORD RESET REQUIRED"
    body="For your security, please reset your password. Please check your email for instructions"
    primaryAction={() => setIsOpen(false)}
    onClose={(e) => handleClose(e)}
    modalId="pwResetRequired"
    primaryText="Close"
    showSecondary={false}
    className="pwResetRequiredModal"
  />
);
