/**
 * this file is for storing methods to communicate back and forth
 * between the webpage and native app (android & ios).
 */

export function isWebview() {
  return !!(
    window?.webkit?.messageHandlers?.fabricCreateOnNav || window?.webview?.fabricCreateOnNav
  );
}
