export const PDP_COUNTER_RESET_KEY = '@pdpCounterResetDate';
export const PDP_COUNTER = '@pdpCounter';

export const GalleryAssetName = {
  desktop: {
    composed: '600x600',
    standalone_legacy: '500x680',
    standalone: '500x680_optimized',
  },
  mobile: {
    composed: '372x372',
    standalone_legacy: '250x340',
    standalone: '250x340_optimized',
  },
};
