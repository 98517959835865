import {evite} from 'evite';
import {ModalBase} from './base';
import {htmlFromString} from '~/common/utils';

function buildScriptTag({
  src,
  type,
  defer,
  async,
  onLoad,
  onError,
  className,
  innerText,
  innerHTML,
}) {
  defer = Boolean(defer);
  async = Boolean(async);
  onLoad = onLoad || '';
  onError = onError || '';
  className = className || '';
  innerText = innerText || '';

  const id = evite.guid('script-id');

  const scriptTag = document.createElement('script');
  scriptTag.type = type || 'text/javascript';
  scriptTag.async = async;
  scriptTag.defer = defer;
  scriptTag.className = className;

  scriptTag.addEventListener('load', () => {
    evite.resolve(src);
    onLoad();
  });

  scriptTag.addEventListener('error', () => {
    evite.reject(src);
    onError();
  });
  if (src) {
    scriptTag.src = src;
  } else if (innerHTML) {
    scriptTag.innerHTML = innerHTML;
  } else if (innerText) {
    scriptTag.innerText = innerText;
  }

  return scriptTag;
}

export class EviteModal {
  target = null;

  modalHead = null;

  modalBody = null;

  parser = null;

  open = false;

  id = null;

  defaults = {
    popup: false,
    width: '480px',
    parse: true,
    buttonLocators: {},
    dataQaId: null,
    buttonsDataImpression: [],
  };

  constructor(settings) {
    this.settings = {
      ...this.defaults,
      ...settings,
    };
    this.id = evite.guid();
    this.modal = ModalBase.getInstance();
    this.parser = this.modal.parser;
    this.buildContainer();
  }

  overwrites() {
    this.settings.overwrites.forEach((ow) => {
      document.getElementById(ow.id).innerHTML = ow.text;
    });
  }

  buildContainer() {
    this.target = htmlFromString(
      `<div id="eviteModal-${
        this.id
      }" class="eviteModalContentOuter"><div class="eviteModalContainer ${
        this.settings.className ? this.settings.className : ''
      }" ${this.settings.dataQaId ? `data-qa-id="${this.settings.dataQaId}"` : ''}>${
        this.settings.closeBtn
          ? '<button id="eviteModalCloseBtn" class="eviteModalCloseBtn">X</button>'
          : ''
      }<div class="eviteModalContent"></div></div></div>`
    );
    this.target.querySelector('.eviteModalContainer').style.cssText = `
            max-width: ${this.settings.width};`;
  }

  encodeUrlParams(data) {
    const ret = [];
    for (const d in data) ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`);
    return ret.join('&');
  }

  _query_string() {
    const params = {
      p: JSON.stringify(this.settings.url_parameters),
      parent_url: document.location.href,
    };
    return `?${this.encodeUrlParams(params)}`;
  }

  fetchContent = () => {
    let {url} = this.settings;
    if (this.settings.forceDesktop) {
      url += '_desktop';
    }
    url += this.settings.url_parameters ? this._query_string() : '';
    return new Promise((resolve, reject) => {
      evite
        .fetch(url, {
          method: 'get',
          credentials: 'same-origin',
          cache: 'no-cache',
        })
        .then((response) => {
          resolve(response.text());
        })
        .catch((message) => {
          reject(message);
        });
    });
  };

  parseContent = (text) => this.parser.parseFromString(text, 'text/html');

  injectContent = (page) => {
    this.modalHead = page.head;
    this.modalBody = page.body.innerHTML;
    this.target.querySelector('.eviteModalContent').innerHTML = this.modalBody;
    return this;
  };

  buildPopup() {
    return new Promise((resolve, reject) => {
      if (!this.settings.body) {
        reject('"body" is required');
      }
      if (!this.settings.title) {
        reject('"title" is required');
      }
      if (!this.settings.buttons && !this.settings.buttonMarkup) {
        reject('"buttons" or "button markup" is required');
      }
      const {buttons} = this.settings;
      const {buttonLocators} = this.settings;
      const {buttonsDataImpression} = this.settings;
      let button_markup = this.settings.buttonMarkup ? this.settings.buttonMarkup : '';
      if (!button_markup && buttons) {
        for (const key in buttons) {
          const button = buttons[key];
          const buttonLocator = buttonLocators[key];
          const buttonDataImpression = buttonsDataImpression[key];
          const buttonId = button.toLowerCase().split(' ').join('-').trim();
          const dataImpression = buttonDataImpression ? 'data-impression-tracking="true"' : '';
          if (buttons.length === 1 * key + 1) {
            button_markup += `<button class="button primary primary-button button-group-item ${
              evite.detects.isMobile ? 'small' : ''
            }" data-qa-id="${
              buttonLocator || buttonId
            }" data-name="${button}" data-index="${key}" ${dataImpression}>${button}</button>`;
          } else {
            button_markup += `<button class="button primary-inverse secondary secondary-button button-group-item ${
              evite.detects.isMobile ? 'small' : ''
            }" data-qa-id="${
              buttonLocator || buttonId
            }" data-name="${button}" data-index="${key}" ${dataImpression}>${button}</button>`;
          }
        }
      }
      let modalContent;
      if (this.settings.parse) {
        modalContent = `<p>${this.settings.body}</p>`;
      }
      const container = this.target.querySelector('.eviteModalContent');
      container.innerHTML = `
        <div data-qa-id='${
          this.settings.dataQaId ? this.settings.dataQaId : 'popup-modal'
        }' class='popup-modal evite-modal-content-container'>
          <div class="modal-header">
            <h1 class="subtitle">${this.settings.title}</h1>
          </div>
          <div class="modal-body">
            <div class="wrapper">${modalContent || ''}</div>
          </div>
          <div class="modal-footer"><div class="button-group">${button_markup}</div></div>
        </div>`;

      if (!this.settings.parse) {
        container.querySelector('.wrapper').appendChild(this.settings.body);
      }

      const self = this;
      function respondToPopup(e) {
        self.close();
        const name = e.currentTarget.innerText
          .split(' ')
          .join('')
          .replace(/[^\w\s]/gi, ''); // remove spaces and special characters
        const on_response = self.settings[`on${name}`];
        if (on_response) {
          on_response(name, e);
        } else if (self.settings.onResponse) {
          self.settings.onResponse.apply(self, [e, self, name]);
        }
      }
      const buttonsEl = container.querySelectorAll('.modal-footer button').length
        ? container.querySelectorAll('.modal-footer button')
        : container.querySelectorAll('.modal-footer a');
      for (const key of Object.keys(this.settings.buttons)) {
        buttonsEl[key].addEventListener('click', respondToPopup);
      }
      resolve(true);
    });
  }

  parseDependencies = () => {
    const scripts = Array.from(this.modalHead.querySelectorAll('script'));
    const links = Array.from(this.modalHead.querySelectorAll('link'));
    const inlineStyles = Array.from(this.modalHead.querySelectorAll('style'));
    const styles = links.filter((link) => link.type === 'text/css');
    styles.forEach((style) => {
      style.className = 'eviteModalStyle';
      document.head.appendChild(style);
    });
    inlineStyles.forEach((s) => {
      document.head.appendChild(s);
    });

    scripts.forEach((script) => {
      let scriptTag;
      const src = script.getAttribute('src');
      if (src) {
        scriptTag = buildScriptTag({
          src,
          type: 'text/javascript',
          async: true,
          className: 'eviteModalScript',
          onLoad: () => false,
        });
      } else {
        scriptTag = buildScriptTag({
          type: 'text/javascript',
          innerHTML: script.innerHTML,
          className: 'eviteModalScript',
          onLoad: () => false,
        });
      }
      document.head.appendChild(scriptTag);
    });

    return this;
  };

  buildRemote() {
    return new Promise((resolve, reject) => {
      this.fetchContent()
        .then((text) => this.parseContent(text))
        .then((page) => this.injectContent(page))
        .then(this.parseDependencies)
        .then(resolve)
        .catch((msg) => reject(msg));
    });
  }

  buildFromResponse = (serverResponse) => {
    const page = this.parseContent(serverResponse);
    this.injectContent(page);
    this.parseDependencies();
  };

  close = () =>
    new Promise((resolve, reject) => {
      if (this.open) {
        this.modal.close().then(() => {
          this.open = false;
          const modalDom = document.getElementById(`eviteModal-${this.id}`);
          const styles = document.querySelectorAll('.eviteModalStyle');
          const scripts = document.querySelectorAll('.eviteModalScript');
          Array.from(styles).forEach((el) => el.parentNode.removeChild(el));
          Array.from(scripts).forEach((el) => el.parentNode.removeChild(el));
          if (modalDom) modalDom.parentNode.removeChild(modalDom);
          resolve();
        });
      }
    });

  respond = (options, settings, event) => {
    this.modal.respond(options, settings, event);
  };

  show(htmlResponse) {
    document.querySelector('body').appendChild(this.target);
    this.modal.initialize(this);
    if (htmlResponse && !this.settings.popup) {
      this.buildFromResponse(htmlResponse);
      this.modal.open(`#eviteModal-${this.id}`);
      this.open = true;
      return;
    }
    if (this.settings.popup) {
      this.buildPopup().then(() => {
        this.modal.open(`#eviteModal-${this.id}`);
        this.open = true;
        if (this.settings.overwrites && this.settings.overwrites.length) {
          this.overwrites();
        }
      });
    } else {
      this.buildRemote().then(() => {
        this.modal.open(`#eviteModal-${this.id}`);
        this.open = true;
        if (this.settings.overwrites && this.settings.overwrites.length) {
          this.overwrites();
        }
      });
    }
  }
}
