import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const PB_EnvelopeSent = (props) => {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 44;
  const height = 36;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44 34.014C43.9963 34.5392 43.7862 35.042 43.415 35.4136C43.0438 35.7852 42.5412 35.9958 42.016 36H5.984C5.45763 35.9995 4.95299 35.79 4.58098 35.4176C4.20896 35.0452 4 34.5404 4 34.014V32H40V8.6L24 23L4 5V2C4 1.46957 4.21071 0.960859 4.58579 0.585786C4.96086 0.210714 5.46957 0 6 0H42C42.5304 0 43.0391 0.210714 43.4142 0.585786C43.7893 0.960859 44 1.46957 44 2V34.014ZM8.868 4L24 17.62L39.132 4H8.868ZM0 24H16V28H0V24ZM0 14H10V18H0V14Z"
        fill={color}
      />
    </svg>
  );
};
