import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {PB_Chevron as Chevron} from '~/common/svg/PB_Chevron';
import {Link} from '~/common/_pb_components/atoms/Link';
import {NewCategoryTag} from '~/MegaNav/NewCategoryTag/NewCategoryTag';

import './MWCategoryViewAB.sass';

export const MWCategoryViewAB = ({
  category,
  title,
  linkTabIndex,
  level,
  setLevel1Category,
  setLevel2Category,
  setNavLevel,
  allHref,
}) => {
  const {href, dataQaId, new: newTag, hideSubCategories, name} = category;

  /** Expand the category onClick */
  const openCategory = useCallback(() => {
    if (level === 0) {
      setLevel1Category(category);
    } else {
      setLevel2Category(category);
    }
    setNavLevel(level + 1);
  }, [level, category, setLevel1Category, setLevel2Category, setNavLevel]);

  /** Build the href */
  const newHref = useMemo(() => {
    if (!href) return '';
    if (href.startsWith('https://')) return href;
    const url = new URL(href, window.location.origin);
    return url.toString();
  }, [href]);

  const categoryId = title.toLowerCase().replaceAll(' ', '-');
  const isLink = level === 2 || hideSubCategories;

  return isLink ? (
    <Link
      href={newHref || allHref}
      data-qa-id={dataQaId || `${name}_subcategoryLink`}
      className="unstyled-button mw-category-view__nav-link"
    >
      <div className="mw-category-view__nav-link-inner" key={`${categoryId}_link`}>
        {title}
        {newTag ? <NewCategoryTag /> : null}
      </div>
      <Chevron ratio={0.5} direction="right" />
    </Link>
  ) : (
    <button
      className="unstyled-button mw-category-view__nav-link"
      onClick={openCategory}
      tabIndex={linkTabIndex}
      data-qa-id={`${categoryId}_toggle`}
      type="button"
    >
      <div
        className="mw-category-view__nav-link-inner"
        data-qa-id={dataQaId}
        key={`${categoryId}_link`}
      >
        {title}
        {newTag ? <NewCategoryTag /> : null}
      </div>
      <Chevron ratio={0.5} direction="right" />
    </button>
  );
};

MWCategoryViewAB.propTypes = {
  category: PropTypes.shape({
    href: PropTypes.string,
    dataQaId: PropTypes.string,
    new: PropTypes.bool,
    hideSubCategories: PropTypes.bool,
    name: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  linkTabIndex: PropTypes.number.isRequired,
  level: PropTypes.number.isRequired,
  setLevel1Category: PropTypes.func.isRequired,
  setLevel2Category: PropTypes.func.isRequired,
  setNavLevel: PropTypes.func.isRequired,
};
