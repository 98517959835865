const defaults = {
  modal: '.evite-modal-container',
  modalInner: '.modal-inner',
  modalContent: '.modal-content',
  open: '[data-modal-open]',
  close: '[data-modal-close]',
  closeAction: '[data-close-action]',
  dataAttribute: 'data-current-modal',
  page: 'body',
  class: 'modal-visible',
  loadClass: 'evite-modal',
  clickOutside: true,
  closeKeys: [27],
  transitions: true,
  transitionEnd: null,
  onBeforeOpen: null,
  onBeforeClose: null,
  onOpen: null,
  onClose: null,
  allowClose: true,
};

function throwError(message) {
  // eslint-disable-next-line no-console
  console.error(`ModalView: ${message}`);
}

function find(arr, callback) {
  return (key) => {
    const filteredArray = arr.filter(callback);
    return filteredArray[0] ? filteredArray[0][key] : undefined;
  };
}

function transitionEndVendorSniff() {
  const el = document.createElement('div');
  const transitions = [
    {key: 'transition', value: 'transitionend'},
    {key: 'OTransition', value: 'otransitionend'},
    {key: 'MozTransition', value: 'transitionend'},
    {key: 'WebkitTransition', value: 'webkitTransitionEnd'},
  ];
  return find(transitions, ({key}) => typeof el.style[key] !== 'undefined')('value');
}

function isPopulatedArray(arr) {
  return Object.prototype.toString.call(arr) === '[object Array]' && arr.length;
}

function getNode(selector, parent, skipError = false) {
  const targetNode = parent || document;
  const node = targetNode.querySelector(selector);
  if (!node && !skipError) {
    throwError(`${selector} not found in document.`);
  }
  return node;
}

function addClass(el, className) {
  if (!(el instanceof HTMLElement)) {
    throwError('Not a valid HTML element.');
  }
  el.setAttribute(
    'class',
    el.className
      .split(' ')
      .filter((cn) => cn !== className)
      .concat(className)
      .join(' ')
  );
}

function removeClass(el, className) {
  if (!(el instanceof HTMLElement)) {
    throwError('Not a valid HTML element.');
  }
  el.setAttribute(
    'class',
    el.className
      .split(' ')
      .filter((cn) => cn !== className)
      .join(' ')
  );
}

function getElementContext(e) {
  if (e && typeof e.hash === 'string') {
    return document.querySelector(e.hash);
  }
  if (typeof e === 'string') {
    return document.querySelector(e);
  }
  throwError('No selector supplied to open()');
  return null;
}

function applyModalSettings(settings) {
  return {
    ...defaults,
    ...settings,
    transitionEnd: transitionEndVendorSniff(),
  };
}

function matches(e, selector) {
  const allMatches = (e.target.document || e.target.ownerDocument).querySelectorAll(selector);
  for (let i = 0; i < allMatches.length; i += 1) {
    let node = e.target;
    while (node && node !== document.body) {
      if (node === allMatches[i]) {
        return node;
      }
      node = node.parentNode;
    }
  }
  return null;
}

export class ModalBase {
  currentInstance = null;

  parser = null;

  openCloseQueue = null;

  constructor(options) {
    if (options && options.loader) {
      if (!window.EviteLoadingModal) {
        window.EviteLoadingModal = this;
      } else {
        return window.EviteLoadingModal;
      }
    } else if (!window.EviteModal) {
      window.EviteModal = this;
    } else {
      return window.EviteModal;
    }

    this.parser = new DOMParser();
    this.openCloseQueue = Promise.resolve();

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.closeKeyHandler = this.closeKeyHandler.bind(this);
    this.outsideClickHandler = this.outsideClickHandler.bind(this);
    this.delegateOpen = this.delegateOpen.bind(this);
    this.delegateClose = this.delegateClose.bind(this);
    this.listen = this.listen.bind(this);
    this.destroy = this.destroy.bind(this);
  }

  static getInstance() {
    return window.EviteModal || new ModalBase();
  }

  initialize(modalInstance) {
    this.current = null;

    this.settings = applyModalSettings(modalInstance.settings);
    this.dom = this.getDomNodes();
    this.currentInstance = modalInstance;

    this.addLoadedCssClass();
    this.listen();
  }

  getDomNodes() {
    const {modal, page, modalInner, modalContent} = this.settings;
    return {
      modal: getNode(modal),
      page: getNode(page),
      modalInner: getNode(modalInner, getNode(modal)),
      modalContent: getNode(modalContent, getNode(modal)),
    };
  }

  addLoadedCssClass() {
    addClass(this.dom.page, this.settings.loadClass);
  }

  setOpenId(id) {
    const {page} = this.dom;
    page.setAttribute(this.settings.dataAttribute, id || 'anonymous');
  }

  removeOpenId() {
    const {page} = this.dom;
    page.removeAttribute(this.settings.dataAttribute);
  }

  open(allMatches, e) {
    return this.openCloseQueue.then(() => {
      // Try to blur any active elements before opening modal
      if (document.activeElement) document.activeElement.blur();

      const {page, modalContent, modalInner} = this.dom;
      const {onBeforeOpen, onOpen} = this.settings;
      if (!(this.current instanceof HTMLElement === false)) {
        throwError('ModalView target must exist on page.');
        return;
      }
      if (this.current) this.releaseNode(this.current);
      this.current = getElementContext(allMatches);

      if (!this.current) {
        evite.error(`Could not locate node: ${allMatches}`);
        return;
      }

      if (!this.settings.allowClose) {
        getNode(this.settings.close).style.display = 'none';
      }
      if (typeof onBeforeOpen === 'function') {
        onBeforeOpen.call(this, e);
      }

      this.captureNode(this.current);
      this.setOpenId(this.current.id);
      this.isOpen = true;
      addClass(page, this.settings.class);
      if (evite.detects.isMobile) {
        // this is part of a fix for ios Safari devices that have webkit bugs on input fields
        // when this is finally fixed we can remove this and the absolute positioning in SimpleModal/styles.sass
        //   https://hackernoon.com/how-to-fix-the-ios-11-input-element-in-fixed-modals-bug-aaf66c7ba3f8
        // until then, switching from fixed to absolute messes up the positioning, with modals sometimes appearing
        // offscreen when fixed would have positioned them in viewport.
        evite.css(modalInner, {
          position: 'absolute',
          left: '2.5%',
          top: window.scrollY + 40,
          // the modal is not visible on ios without this
          'z-index': '-1',
        });

        // centers the modal for mobile / tablet
        evite.css(modalContent, {
          'max-width': '430px',
          margin: '0px auto',
        });
      }

      // set focus on last input element or anchor in the modal EVT-10809
      const lastInput = Array.from(modalContent.querySelectorAll('input, button, a')).pop();
      if (lastInput) lastInput.focus();

      if (typeof onOpen === 'function') {
        onOpen.call(this, e);
      }
    });
  }

  detectTransition() {
    const {modal} = this.dom;
    const css = window.getComputedStyle(modal, null);
    return Boolean(
      [
        'transitionDuration',
        'oTransitionDuration',
        'MozTransitionDuration',
        'webkitTransitionDuration',
      ].filter((i) => typeof css[i] === 'string' && parseFloat(css[i]) > 0).length
    );
  }

  close(e) {
    return this.openCloseQueue.then(
      () =>
        new Promise((resolve) => {
          const {transitions, transitionEnd, onBeforeClose} = this.settings;
          const hasTransition = this.detectTransition();
          if (this.isOpen) {
            if (typeof onBeforeClose === 'function') {
              onBeforeClose.call(this, e);
            }
            if (transitions && transitionEnd && hasTransition) {
              this.closeModalWithTransition(e).then(resolve);
            } else {
              this.closeModal(e).then(resolve);
            }
            removeClass(this.dom.page, this.settings.class);
          } else {
            resolve();
          }
        })
    );
  }

  closeModal(e) {
    return this.openCloseQueue.then(
      () =>
        new Promise((resolve) => {
          const {onClose} = this.settings;
          this.removeOpenId(this.dom.page);
          this.releaseNode(this.current);
          removeClass(this.dom.page, this.settings.loadClass);
          if (typeof onClose === 'function') {
            onClose.call(this, e);
          }
          this.current = null;
          this.isOpen = false;
          resolve();
        })
    );
  }

  closeTransitionHandler = (e) => {
    if (this.resolveTransition) {
      this.resolveTransition(this.closeModal(e));
      this.resolveTransition = null;
    }
  };

  closeModalWithTransition(e) {
    return this.openCloseQueue.then(
      () =>
        new Promise((resolve) => {
          this.resolveTransition = resolve;
        })
    );
  }

  captureNode(node) {
    const {modalContent} = this.dom;
    while (node.childNodes.length) {
      modalContent.appendChild(node.childNodes[0]);
    }
  }

  releaseNode(node) {
    const {modalContent} = this.dom;
    while (modalContent.childNodes.length) {
      modalContent.removeChild(modalContent.childNodes[0]);
    }
  }

  closeKeyHandler(e) {
    const {closeKeys, closeAction} = this.settings;

    if (isPopulatedArray(closeKeys) && closeKeys.indexOf(e.which) > -1 && this.isOpen === true) {
      e.preventDefault();
      this.doCancel();
    }
  }

  outsideClickHandler(e) {
    const {clickOutside} = this.settings;
    const {modalInner} = this.dom;

    if (clickOutside) {
      let node = e.target;
      while (node && node !== document.body) {
        if (node === modalInner) {
          return;
        }
        node = node.parentNode;
      }
      this.doCancel();
    }
  }

  doCancel() {
    const {onCancel, closeAction} = this.settings;
    const closeNode = getNode(closeAction, document, true);

    if (typeof onCancel === 'function') {
      onCancel();
    }
    // if an element has been identified as the close-action node, click that instead of closing
    if (closeNode) {
      closeNode.click();
    } else {
      this.close();
    }
  }

  delegateOpen(e) {
    const {open} = this.settings;
    const matchedNode = matches(e, open);
    if (matchedNode) {
      e.preventDefault();
      this.open(matchedNode, e);
    }
  }

  delegateClose(e) {
    const {close} = this.settings;
    if (matches(e, close)) {
      e.preventDefault();
      this.doCancel();
    }
  }

  listen() {
    if (this.isListening) {
      this.unlisten();
    }
    const {modal} = this.dom;
    const {transitionEnd} = this.settings;
    if (!this.isListening) {
      modal.addEventListener(transitionEnd, this.closeTransitionHandler);
      modal.addEventListener('click', this.outsideClickHandler, false);
      document.addEventListener('keydown', this.closeKeyHandler, false);
      document.addEventListener('click', this.delegateOpen, false);
      document.addEventListener('click', this.delegateClose, false);
      this.isListening = true;
    } else {
      throwError('Event listeners already applied.');
    }
  }

  unlisten() {
    const {modal} = this.dom;
    const {transitionEnd} = this.settings;
    modal.removeEventListener('click', this.outsideClickHandler);
    modal.removeEventListener(transitionEnd, this.closeTransitionHandler);
    document.removeEventListener('keydown', this.closeKeyHandler);
    document.removeEventListener('click', this.delegateOpen);
    document.removeEventListener('click', this.delegateClose);
    this.isListening = false;
  }

  respond(response, settings, e) {
    let useSettings = this.settings;
    if (settings) {
      useSettings = Object.assign(useSettings, settings);
    }
    this.close().then(() => {
      window.tags.page_event(useSettings.url, response.name);
      const name = response.name.split(' ').join(''); // remove spaces
      const on_response = useSettings[`on${name}`];
      if (on_response) {
        on_response(response, e);
      } else if (useSettings.onResponse) {
        useSettings.onResponse(response, e);
      }
    });
  }

  destroy() {
    if (this.isListening) {
      this.close();
      this.unlisten();
    } else {
      throwError('Event listeners already removed.');
    }
  }
}
