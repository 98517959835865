import PropTypes from 'prop-types';
import {Typography} from '~/common/_pb_components/atoms/Typography';

export const UserCallout = ({variant = 'paragraph2', className, dataQaId, name}) => {
  const parser = new DOMParser();
  return (
    <Typography
      as="p"
      variant={variant}
      classNames={className}
      data-qa-id={dataQaId}
      color="charcoal"
    >
      Hi,{' '}
      {parser.parseFromString(`<!doctype html><body>${name}</body>`, 'text/html').body.textContent}!
    </Typography>
  );
};

UserCallout.propTypes = {
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
  className: PropTypes.string,
  dataQaId: PropTypes.string,
};
