/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-lines-per-function */
/** Global imports */
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {createRoot} from 'react-dom/client';
import {useDispatch, useStore} from 'react-redux';
import urls from 'urls';
import {getNewContent, getLocalStorageContent} from '~/common/cache_utils';
import {getUserData, USER_DATA_STORAGE_KEY} from '~/common/auth/utils';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {Video} from '~/common/_pb_components/atoms/Video';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Picture} from '~/common/_pb_components/atoms/Picture';
import {PB_AccountLine as PBAccountLine} from '~/common/svg/PB_AccountLine';
import {PB_Premium as PBPremium} from '~/common/svg/PB_Premium';

/** Local imports */
import {useShouldShowInlineSearch} from './utils';
import {AccountDetailsDrawer} from '~/MegaNav/AccountDetailsDrawer/AccountDetailsDrawer';
import {AccountPreview} from '~/MegaNav/AccountPreview/AccountPreview';
import {CategoryDrawer} from '~/MegaNav/CategoryDrawer/CategoryDrawer';
import {DesktopExpandedCategoryView} from '~/MegaNav/DesktopExpandedCategoryView/DesktopExpandedCategoryView';
import {LoginWrapper} from '~/LoginModal/LoginWrapper';
import {NotificationsWrapper} from '~/MegaNav/NotificationsWrapper/NotificationsWrapper';
import {SearchWithCategoryWrapper} from '~/MegaNav/SearchWithCategory/SearchWithCategory';
import {MWCategoryDrawerAB} from './CategoryDrawer/MWCategoryDrawerAB';
import {userLoggedIn} from '~/common/components/LoginModal/reducer';
import {PLAN_NAME_PRO} from '~/common/fabric/routes/EventReview/constants';

import {fog} from '~sass/pb_styleguide/base/_exports.sass';
import './MegaNav.sass';

export const categoryHasAssets = (category) =>
  !!(category && category.assets && category.assets.length);

const EviteProPromoWrapper = (hoveredCategory, eviteProData) => {
  if (!hoveredCategory?.eviteProTagLine) return null;
  const {eviteProTagLine} = hoveredCategory;
  const {href, isVideo, srcSet, options, altText} = eviteProData;
  return (
    <div
      className="meganav__desktop-evite-pro__promo"
      onClick={() => {
        window.location.href = href;
      }}
      data-qa-id="eviteProMegaNavAsset"
      aria-label="evite-promo"
      role="button"
      tabIndex="-1"
      onKeyDown={null}
    >
      <PBPremium className="meganav__desktop-evite-pro__icon" ratio={1} color={fog} />
      {isVideo ? (
        <Video className="meganav__desktop-evite-pro__logo" src={srcSet} options={options} />
      ) : (
        <Picture
          className="meganav__desktop-evite-pro__logo"
          srcSet={srcSet}
          alt={altText}
          loading="lazy"
        />
      )}
      <Typography variant="header3" classNames="meganav__desktop-evite-pro__tagline">
        {eviteProTagLine}
      </Typography>
      <Typography classNames="meganav__desktop-evite-pro__cta" variant="paragraph2">
        Learn More
      </Typography>
    </div>
  );
};

const ExpandedCategoryViewAssets = (hoveredCategory) => {
  if (!hoveredCategory || !categoryHasAssets(hoveredCategory)) return null;
  const {assets} = hoveredCategory;
  return (
    <div className="meganav__invite-assets">
      {assets.map((asset) => (
        <a key={asset.href} href={asset.href} data-qa-id={asset.dataQaId}>
          <div className="meganav__desktop-expanded-category__asset-wrapper">
            {asset.isVideo ? (
              <Video src={asset.srcSet} options={asset.options} />
            ) : (
              <Picture srcSet={asset.srcSet} alt={asset.altText || ''} loading="lazy" />
            )}
            {asset.text && (
              <Typography
                variant={asset.textVariant || 'paragraph3'}
                classNames="meganav__desktop-expanded-category__text-over-asset-wrapper"
                style={{
                  '--main-nav-expanded-asset-text-color': asset.fontColor || null,
                }}
              >
                {asset.text}
              </Typography>
            )}
          </div>
        </a>
      ))}
    </div>
  );
};

let accountRoot = null;
export const MegaNav = () => {
  let dispatch = null;
  let store = null;
  try {
    dispatch = useDispatch();
    store = useStore();
  } catch (e) {
    // ignore, we must be on the gallery page
  }
  // Bucket user into meganav design AB/ABC tests
  const navDesignVariationDesktop = evite.experiments.getOrSetVariation(
    'redesign-meganav-desktop',
    'control'
  );
  const navDesignVariationMW = evite.experiments.getOrSetVariation(
    'redesign-meganav-mw',
    'control'
  );

  const searchRootRef = useRef(null);

  /** State hooks */
  const [inSliderVariant, setInSliderVariant] = useState(false);
  const [mwCategoryDrawerOpen, setMwCategoryDrawerOpen] = useState(false);
  const [acctDetailsDrawerOpen, setAcctDetailsDrawerOpen] = useState(false);
  const [notificationsDrawerOpen, setNotificationsDrawerOpen] = useState(false);
  const [notificationsObj, setNotificationsObj] = useState({
    notifications: [],
    unviewed: 0,
    unread: 0,
  });
  const [hoveredCategory, setHoveredCategory] = useState();
  const [megaNavData, setMegaNavData] = useState(getLocalStorageContent('meganav_content'));
  const [ECVHovered, setECVHovered] = useState(false);
  const listenersSet = useRef(false);
  const [userData, setUserData] = useState(() => getUserData());
  const {matches: lgWindow} = useMatchQuery('(min-width: 80rem)', []);
  const shouldHideMeganavSearch = useShouldShowInlineSearch();

  /** Check for loginModal in the redux. We'll handle onSignInClick differently if found */
  const foundLoginModalRedux = useMemo(() => {
    let found = false;
    if (dispatch && store) {
      try {
        const state = store.getState();
        found =
          state.loginModal &&
          Object.prototype.hasOwnProperty.call(state.loginModal, 'showLoginModal');
      } catch (error) {
        found = false;
      }
    }
    return found;
  }, [dispatch, store]);

  const meganavSearch = document.getElementById('meganav-search');
  const pbMegaNavInner = document.getElementById('pbMegaNavInner');

  if (meganavSearch) {
    if (!shouldHideMeganavSearch) {
      meganavSearch.classList.remove('hidden');
      pbMegaNavInner.classList.remove('no-search');
    } else {
      meganavSearch.classList.add('hidden');
      pbMegaNavInner.classList.add('no-search');
    }
  }

  /** Handle clicking on the backdrop of a nav drawer on mobile web */
  const onDrawerBackdropClick = () => setMwCategoryDrawerOpen(false);

  const hideLoginModal = () => {
    document.getElementsByClassName('nav-drawer-overlay')[0].classList.remove('drawer-open');
    document.getElementsByClassName('meganav-auth-slideout')[0].classList.remove('open');
    document.body.classList.remove('no-scroll');
  };

  /** Handle clicking on the backdrop of the login/signup modal */
  const onLoginModalBackdropClick = () => {
    hideLoginModal();
    if (window.loginPromise) {
      window.loginPromise.reject();
    }
  };

  /** Handle clicking on the backdrop of the account details drawer */
  const onAcctDetailsDrawerBackdropClick = () => setAcctDetailsDrawerOpen(false);

  /** Handle clicking the Sign-In button in the nav */
  const onSignInClick = useCallback(() => {
    document.getElementsByClassName('nav-drawer-overlay')[0].classList.add('drawer-open');
    document.getElementsByClassName('meganav-auth-slideout')[0].classList.add('open');
    document.body.classList.add('no-scroll');
  }, []);

  const hideAcctDetailsDrawerIfOpen = (e) => {
    if (!lgWindow) return;
    const acctDetails = document.getElementById('acctDetails');

    if (acctDetailsDrawerOpen && e.target !== acctDetails && !acctDetails.contains(e.target)) {
      setAcctDetailsDrawerOpen(false);
    }
  };

  const hideNotificationsDrawerIfOpen = (e) => {
    if (!lgWindow) return;
    const notificationsMenu = document.getElementById('notificationsMenu');

    if (
      notificationsDrawerOpen &&
      e.target !== notificationsMenu &&
      !notificationsMenu.contains(e.target)
    ) {
      setNotificationsDrawerOpen(false);
    }
  };

  /** Handle toggle open/close of account and notifications menus */
  const onAccountDetailsClick = useCallback(() => {
    if (notificationsDrawerOpen) setNotificationsDrawerOpen(false);
    // hack because we aren't showing/hiding components properly
    window.setTimeout(() => setAcctDetailsDrawerOpen(!acctDetailsDrawerOpen), 100);
  }, [acctDetailsDrawerOpen]);

  const onNotificationsClick = useCallback(() => {
    if (lgWindow) {
      if (acctDetailsDrawerOpen) setAcctDetailsDrawerOpen(false);
      // hack because we aren't showing/hiding components properly
      window.setTimeout(() => setNotificationsDrawerOpen(!notificationsDrawerOpen), 100);
    } else {
      window.location.href = '/all-notifications';
    }
  }, [notificationsDrawerOpen]);

  /** Handle a successful login event */
  const onLoginSuccess = useCallback(() => {
    hideLoginModal();
    const data = getUserData();
    if (dispatch && foundLoginModalRedux) {
      dispatch(
        userLoggedIn(
          data.userId,
          data.userName,
          data.subscriptionPlanName,
          data.email,
          data.avatarDisk,
          data.imgUrl,
          data.initials,
          data.firstName
        )
      );
    }
    setUserData(data);
    if (window.loginPromise || foundLoginModalRedux) {
      window.loginPromise?.resolve();
      return;
    }
    // TODO: We should remove the reload on login soon!
    window.location.reload();
  }, [hideLoginModal, dispatch, foundLoginModalRedux]);

  /** Handle a successful signup event */
  const onSignupSuccess = useCallback(() => {
    onLoginSuccess();
  }, [onLoginSuccess]);

  /** Handle an update to the status of a notification (e.g. read, hidden, etc.) */
  const updateNotificationStates = (
    idsToUpdate,
    prop,
    val,
    successCallback,
    errorCallback = null
  ) => {
    // TODO - Ask Rudy if this needs to be sent when idsToUpdate is empty.
    const url = urls.get('ajax_notify_update');
    const payload = {messages: idsToUpdate};
    payload[prop] = val;
    window.evite
      .fetch(url, {
        method: 'post',
        body: JSON.stringify(payload),
        credentials: 'include',
        cache: 'no-cache',
        headers: {
          'X-CSRFToken': evite.cookie('csrftoken'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.clone().json())
      .then(successCallback)
      .catch((error) => {
        evite.error(`Error while posting to ${url} errorThrown=${error}`);
        if (errorCallback) {
          errorCallback();
        }
      });
  };

  /** Mark all notifications as read */
  const onMarkAllAsRead = () => {
    updateNotificationStates(
      notificationsObj.notifications.filter((n) => !n.read).map((n) => n.created),
      'read',
      true,
      () => {
        const updatedNotifications = {...notificationsObj};
        updatedNotifications.notifications = notificationsObj.notifications.map((n) => ({
          ...n,
          read: true,
        }));
        updatedNotifications.unread = 0;
        updatedNotifications.unviewed = 0;
        setNotificationsObj(updatedNotifications);
      }
    );
  };

  /** Mark a single notification as read */
  const onMarkOneAsRead = (e, id) => {
    e.preventDefault();
    updateNotificationStates([id], 'read', true, () => {
      const updatedNotifications = {...notificationsObj};
      const idx = updatedNotifications.notifications.findIndex((n) => n.created === id);
      if (idx >= 0) {
        updatedNotifications.notifications[idx].read = true;
        if (updatedNotifications.unread > 0) updatedNotifications.unread--;
        if (updatedNotifications.unviewed > 0) updatedNotifications.unviewed--;
      }
      setNotificationsObj(updatedNotifications);
    });
  };

  /** Mark a single notification as unread */
  const onMarkOneAsUnread = (e, id) => {
    e.preventDefault();
    updateNotificationStates([id], 'read', false, () => {
      const updatedNotifications = {...notificationsObj};
      const idx = updatedNotifications.notifications.findIndex((n) => n.created === id);
      if (idx >= 0) {
        updatedNotifications.notifications[idx].read = false;
        if (updatedNotifications.unread > 0) updatedNotifications.unread++;
      }
      setNotificationsObj(updatedNotifications);
    });
  };

  /** Hide a notification from the notification viewer */
  const onHideNotification = (e, id) => {
    e.preventDefault();
    updateNotificationStates(id, 'delete', true, () => {
      const updatedNotifications = {...notificationsObj};
      updatedNotifications.notifications = updatedNotifications.notifications.filter(
        (n) => n.created !== id
      );
      setNotificationsObj(updatedNotifications);
    });
  };

  /** Handle logout */
  const onLogout = async () => {
    window.tags.page_event('signout_button');
    try {
      await window.evite.fetch(urls.get('ajax_logout'), {
        method: 'post',
        credentials: 'include',
        headers: {
          'X-CSRFToken': evite.cookie('csrftoken'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      setUserData({});
    } catch (error) {
      evite.error('Error while posting to ajax_logout', error);
    }
    localStorage.removeItem(USER_DATA_STORAGE_KEY);
    return window.location.assign(urls.get('home'));
  };

  /** Render the desktop version of the expanded category viewer */
  const renderDesktopExpandedCategoryView = useCallback(
    (hoveredCat) => {
      const promoListLength =
        (hoveredCat?.eviteProTagLine ? 1 : 0) + (hoveredCat?.assets?.length ?? 0);
      const inNavDesignVariant = ['B', 'C'].includes(navDesignVariationDesktop);
      const fullerCategory = !!hoveredCat?.show_max_columns;
      const numBaseColumns = fullerCategory ? 6 : 5;
      const columnCount = inNavDesignVariant
        ? numBaseColumns - Math.ceil(promoListLength / 2)
        : undefined;

      const categories = [
        ...(megaNavData?.invitationCategories?.map((ic) => ({
          ...ic,
          type: 'invitation',
        })) || []),
        {...megaNavData?.greetingCardConfig, type: 'card'},
        {...megaNavData?.eGiftCardConfig, type: 'card'},
        {...megaNavData?.signupSheetConfig, type: 'signup-sheet'},
      ];
      const category = categories.find((c) => c.title === hoveredCat?.title);
      if (!category || category.hideSubCategories || !category.subCategories) {
        return null;
      }
      const tightView = hoveredCat?.eviteProTagLine || categoryHasAssets(hoveredCat);
      return (
        <div
          className={`subcategory-wrapper redesign-${navDesignVariationDesktop} ${
            tightView ? 'tight' : ''
          }`}
          style={{columnCount}}
        >
          {category.subCategories.map((sc) => (
            <DesktopExpandedCategoryView
              {...sc}
              category_id={category.id}
              type={category.type}
              key={sc.name}
              data-qa-id={`expandedCategoryView_${sc.id}`}
              navDesignVariationDesktop={navDesignVariationDesktop}
            />
          ))}
        </div>
      );
    },
    [
      navDesignVariationDesktop,
      megaNavData?.invitationCategories,
      megaNavData?.greetingCardConfig,
      megaNavData?.eGiftCardConfig,
      megaNavData?.signupSheetConfig,
      categoryHasAssets,
    ]
  );

  useEffect(() => {
    evite.resolve('nav');

    /** Fetch Meganav Content */
    getNewContent(urls.get('ajax_meganav_content'), 'meganav_content', {
      method: 'get',
      credentials: 'same-origin',
    }).then((response) => {
      if (response) {
        setMegaNavData(response);
      }
    });

    /** meganav.html Injection/Listener */
    if (window.location.pathname !== '/') {
      searchRootRef.current?.unmount();
      searchRootRef.current = createRoot(meganavSearch);
      searchRootRef.current.render(<SearchWithCategoryWrapper />);
    }

    const mwToggle = document.getElementById('menu-hamburger');

    const toggleDrawer = () => {
      setMwCategoryDrawerOpen(!mwCategoryDrawerOpen);
    };

    mwToggle.addEventListener('click', toggleDrawer);

    return () => {
      mwToggle.removeEventListener('click', toggleDrawer);
    };
  }, []);

  useEffect(() => {
    if (mwCategoryDrawerOpen) {
      const sliderDesignVariationMW = evite.experiments.getOrSetVariation(
        'meganav-mw-slider',
        'control'
      );
      setInSliderVariant(sliderDesignVariationMW === 'variant');
    }
  }, [mwCategoryDrawerOpen]);

  useEffect(() => {
    /** Get notifications */
    if (userData?.isAuthenticated) {
      /** Check if logged in */
      window.evite
        .fetch(urls.get('ajax_notify_items'), {
          method: 'get',
          credentials: 'same-origin',
        })
        .then((response) => {
          response.json().then((res) => {
            const data = res || {};
            data.notifications = data.notifications || [];
            setNotificationsObj(data);
          });
        });
    }

    if (userData?.subscriptionPlanName === PLAN_NAME_PRO) {
      document.getElementById('pbMegaNavInner').classList.add('pro');
      document.getElementById('header-evite-logo').src = '/assets/images/logos/evitepro_logo.svg';
    }
  }, [userData]);

  // the storage event only fires for a different tab
  useEffect(() => {
    const checkIfLoggedOut = (event) => {
      if (event?.key === USER_DATA_STORAGE_KEY && event?.newValue === null) {
        setUserData({});
        document.getElementById('pbMegaNavInner').classList.remove('pro');
        document.getElementById('header-evite-logo').src =
          '/assets/images/logos/evite-logo-black.svg';
      }
    };

    window.addEventListener('storage', checkIfLoggedOut);
    return () => {
      window.removeEventListener('storage', checkIfLoggedOut);
    };
  }, []);

  useEffect(() => {
    /** Click listener for the account details drawer on desktop */
    if (acctDetailsDrawerOpen) {
      document.body.addEventListener('click', hideAcctDetailsDrawerIfOpen);
    }
    return () => {
      if (acctDetailsDrawerOpen) {
        document.body.removeEventListener('click', hideAcctDetailsDrawerIfOpen);
      }
    };
  }, [acctDetailsDrawerOpen]);

  useEffect(() => {
    /** Click listener for the notifications drawer on desktop */
    if (notificationsDrawerOpen) {
      document.body.addEventListener('click', hideNotificationsDrawerIfOpen);
    }
    return () => {
      if (notificationsDrawerOpen) {
        document.body.removeEventListener('click', hideNotificationsDrawerIfOpen);
      }
    };
  }, [notificationsDrawerOpen]);

  const showECV = (hoveredCategory?.title && !hoveredCategory?.hideSubCategories) || ECVHovered;

  useEffect(() => {
    const pbMegaNav = document.getElementById('pbMegaNav');

    if (!pbMegaNav) return;

    if (!showECV) {
      pbMegaNav.classList.add('show-box-shadow');
    } else {
      pbMegaNav.classList.remove('show-box-shadow');
    }
  }, [showECV]);

  useEffect(() => {
    if (!megaNavData || listenersSet.current) return undefined;

    const elements = document.querySelectorAll('.nav-link-wrapper');
    const pbHeader = document.getElementById('pbMegaNav');

    const headerMouseoverHandler = () => {
      setHoveredCategory('');
    };

    const mouseoverHandler = (element) => {
      const {id} = element.firstElementChild;
      const cat =
        id === 'cards'
          ? megaNavData.greetingCardConfig
          : megaNavData.invitationCategories.find((ic) => ic.id === id);

      element.classList.add('active');
      setHoveredCategory(cat);
    };

    const mouseoutHandler = (element) => {
      element.classList.remove('active');
    };

    /** Capture event so this fires before the children hover event */
    pbHeader.addEventListener('mouseover', headerMouseoverHandler, true);

    elements.forEach((element) => {
      element.addEventListener('mouseover', () => mouseoverHandler(element));
      element.addEventListener('mouseout', () => mouseoutHandler(element));
    });

    listenersSet.current = true;

    return () => {
      elements.forEach((element) => {
        element.removeEventListener('mouseover', () => mouseoverHandler(element));
        element.removeEventListener('mouseout', () => mouseoutHandler(element));
      });
    };
  }, [megaNavData]);

  const accountDetailsContainer = useMemo(
    () =>
      userData?.isAuthenticated ? (
        <>
          {/** Account Details */}
          <NotificationsWrapper
            notificationsObj={notificationsObj}
            onMarkAllAsRead={onMarkAllAsRead}
            onMarkOneAsRead={onMarkOneAsRead}
            onMarkOneAsUnread={onMarkOneAsUnread}
            onHideNotification={onHideNotification}
            notificationsViewOpen={notificationsDrawerOpen}
            onNotificationsClick={onNotificationsClick}
            navDesignVariationDesktop={navDesignVariationDesktop}
          />
          <AccountPreview
            user={userData}
            onClick={onAccountDetailsClick}
            navDesignVariation={lgWindow ? navDesignVariationDesktop : navDesignVariationMW}
          />

          {/** Account Details Drawer */}
          <AccountDetailsDrawer
            open={acctDetailsDrawerOpen}
            user={userData}
            onClose={onAcctDetailsDrawerBackdropClick}
            onLogout={onLogout}
            navDesignVariationDesktop={navDesignVariationDesktop}
            navDesignVariationMW={navDesignVariationMW}
          />
          {!lgWindow && (
            <div
              className={`nav-drawer-overlay${acctDetailsDrawerOpen ? ' drawer-open' : ''}`}
              onClick={onAcctDetailsDrawerBackdropClick}
              aria-label="nav-drawer-overlay"
              role="button"
              tabIndex="-1"
              onKeyDown={null}
            />
          )}
        </>
      ) : (
        <>
          {/** Sign In Button */}
          <Button
            variant="unstyled-button"
            className="sign-in-button"
            onClick={onSignInClick}
            data-qa-id="meganavSignInButton"
          >
            <Typography
              as="p"
              variant="paragraph2"
              color="charcoal"
              data-qa-id="meganavSignInButtonText"
            >
              Sign in
            </Typography>
            <PBAccountLine ratio={0.75} />
          </Button>

          {/** Sign In Overlay */}
          <div
            className="nav-drawer-overlay"
            onClick={onLoginModalBackdropClick}
            aria-label="nav-drawer-overlay"
            role="button"
            tabIndex="-1"
            onKeyDown={null}
          />
          <div className="meganav-auth-slideout">
            <LoginWrapper
              onSignupSuccess={onSignupSuccess}
              onLoginSuccess={onLoginSuccess}
              onCloser={onLoginModalBackdropClick}
            />
          </div>
        </>
      ),
    [
      userData,
      notificationsObj,
      onMarkAllAsRead,
      onMarkOneAsRead,
      onMarkOneAsUnread,
      onHideNotification,
      notificationsDrawerOpen,
      onNotificationsClick,
      navDesignVariationDesktop,
      onAccountDetailsClick,
      navDesignVariationMW,
      lgWindow,
      acctDetailsDrawerOpen,
      onAcctDetailsDrawerBackdropClick,
      onLogout,
      onSignInClick,
      onLoginModalBackdropClick,
      onSignupSuccess,
      onLoginSuccess,
    ]
  );

  if (!accountRoot) {
    const accountContainer = document.getElementById('account-container');
    if (accountContainer) {
      accountRoot = createRoot(accountContainer);
    }
  }
  useEffect(() => {
    if (accountRoot) {
      accountRoot.render(accountDetailsContainer);
    }
  }, [acctDetailsDrawerOpen, accountRoot, accountDetailsContainer]);

  return !megaNavData ? null : (
    <>
      {/** Desktop Expanded Category View */}
      <div
        className={`desktop-ecv-wrapper redesign-${navDesignVariationDesktop}`}
        id="desktopECVWrapper"
        style={{marginTop: '-1px', display: showECV ? 'block' : 'none'}}
        onMouseEnter={() => setECVHovered(true)}
        onMouseLeave={() => {
          document.querySelectorAll('.nav-link-wrapper').forEach((element) => {
            element.classList.remove('active');
          });
          setHoveredCategory('');
          setECVHovered(false);
        }}
      >
        <div className="desktop-ecv-wrapper-inner" id="desktopECVWrapperInner">
          {renderDesktopExpandedCategoryView(hoveredCategory)}
          {EviteProPromoWrapper(hoveredCategory, megaNavData.eviteProData)}
          {ExpandedCategoryViewAssets(hoveredCategory)}
        </div>
      </div>

      {/* Mobile Category Drawer */}
      {!lgWindow &&
        (inSliderVariant ? (
          <MWCategoryDrawerAB
            open={mwCategoryDrawerOpen}
            closeNav={onDrawerBackdropClick}
            invitationCategories={megaNavData?.invitationCategories}
            greetingCardCategory={megaNavData?.greetingCardConfig}
            eGiftCardCategory={megaNavData?.eGiftCardConfig}
            signupSheetCategory={megaNavData?.signupSheetConfig}
          />
        ) : (
          <CategoryDrawer
            open={mwCategoryDrawerOpen}
            onBackdropClick={onDrawerBackdropClick}
            invitationCategories={megaNavData?.invitationCategories}
            greetingCardCategory={megaNavData?.greetingCardConfig}
            eGiftCardCategory={megaNavData?.eGiftCardConfig}
            signupSheetCategory={megaNavData?.signupSheetConfig}
            navDesignVariationMW={navDesignVariationMW}
          />
        ))}
    </>
  );
};
