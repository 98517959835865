import React, {useState, useEffect, useRef} from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

export const Button = React.forwardRef(
  (
    {
      id,
      className,
      variant = 'primary',
      size = 'small',
      tight = false,
      onClick = null,
      onMouseEnter = null,
      onMouseLeave = null,
      children = null,
      processingChildren = null,
      processingIcon = <span className="evt-spinner inline" />,
      formSubmit = false,
      fullWidth = false,
      disabled = false,
      type = 'button',
      icon = null,
      override = false,
      noTextWrap = false,
      disableProcessing = false,
      title,
      dataClipboardText,
      'data-qa-id': dataQaId,
      'data-qa-value': dataQaValue,
      'data-impression-tracking': dataImpressionTracking,
      'data-qa-tigran': dataQaTigran,
      rounded = false,
    },
    ref
  ) => {
    const [processing, setProcessing] = useState(false);
    const mountedRef = useRef(true);

    const onClickFn = async (e) => {
      // If the props.onClick handler returns a promise we wait until it completes before allowing another click to go through.
      if (processing) return false;
      const value = onClick?.(e);
      if (value && typeof value.then === 'function') {
        if (!disableProcessing) {
          setProcessing(true);
        }
        if (override) {
          e.preventDefault();
          e.stopPropagation();
        }
        value.finally(() => {
          if (mountedRef.current) {
            setProcessing(false);
          }
        });
      }
      return value;
    };

    useEffect(
      () =>
        // track when we are unmounted so we don't update the state after un-mounting
        () => {
          mountedRef.current = false;
        },
      []
    );

    return (
      <button
        id={id}
        data-qa-id={dataQaId}
        data-impression-tracking={dataImpressionTracking}
        data-qa-value={dataQaValue?.toString()}
        data-qa-tigran={dataQaTigran}
        data-clipboard-text={dataClipboardText}
        className={cx('button', className, variant || 'primary', size, {
          small: size !== 'large',
          wide: fullWidth,
          tight,
          nowrap: noTextWrap,
          processing,
          'icon-only': icon && !children,
          rounded,
        })}
        onClick={onClick ? onClickFn : undefined}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        disabled={disabled}
        // eslint-disable-next-line react/button-has-type
        type={type ?? (formSubmit ? 'submit' : 'button')}
        title={title || null}
        aria-label={title}
        ref={ref}
      >
        {processing && processingIcon}
        {icon}
        {processing && processingChildren ? processingChildren : children}
      </button>
    );
  }
);

Button.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'transparent',
    'unstyled-button',
    'link',
    'featured', // currently used for the CF's goh & cohost buttons
  ]),
  size: PropTypes.oneOf(['small', 'large']),
  tight: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  processingChildren: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  processingIcon: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string]),
  formSubmit: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  'data-qa-id': PropTypes.string.isRequired,
  'data-impression-tracking': PropTypes.string,
  'data-qa-value': PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  type: PropTypes.string,
  icon: PropTypes.element,
  override: PropTypes.bool,
  noTextWrap: PropTypes.bool,
  disableProcessing: PropTypes.bool,
  title: PropTypes.string,
  rounded: PropTypes.bool,
};
