// extracted by mini-css-extract-plugin
var _1 = "#2E3136";
var _2 = "#0093BF";
var _3 = "#E8407B";
var _4 = "#ED702D";
var _5 = "#9D52B0";
var _6 = "#00A6A0";
var _7 = "#00A0D1";
var _8 = "#FCB03E";
var _9 = "#28A842";
var _a = "#248538";
var _b = "#FF6998";
var _c = "#FF7C36";
var _d = "#B86BC9";
var _e = "#18CCC6";
var _f = "#3B5998";
var _10 = "#b31412";
var _11 = "#E1E3E6";
var _12 = "#B9BCBF";
var _13 = "#92959B";
var _14 = "#6A6E71";
var _15 = "#2E3136";
var _16 = "#00A0D1";
var _17 = "#0093BF";
var _18 = "#FAAF4A";
var _19 = "#57BBE3";
var _1a = "#A7E7FE";
var _1b = "#FAAF4A";
var _1c = "#E8407B";
var _1d = "#28A842";
var _1e = "#1DA1F2";
var _1f = "#FFFFFF";
export { _1 as "appleColor", _2 as "darkBlue", _3 as "darkMagenta", _4 as "darkOrange", _5 as "darkPurple", _6 as "darkTeal", _7 as "eviteBlue", _8 as "eviteGold", _9 as "eviteGreen", _a as "eviteGreenDark", _b as "eviteMagenta", _c as "eviteOrange", _d as "evitePurple", _e as "eviteTeal", _f as "facebookColor", _10 as "googleColor", _11 as "gray2", _12 as "gray3", _13 as "gray4", _14 as "gray5", _15 as "gray6", _16 as "linkColor", _17 as "linkHoverColor", _18 as "maybeGold", _19 as "paleBlue", _1a as "paleBlueLight", _1b as "rsvpMaybe", _1c as "rsvpNo", _1d as "rsvpYes", _1e as "twitterColor", _1f as "white" }
