import {isWebview} from './webview';

const {getOrSetVariation} = evite.experiments;

export const newCreate = true;

export const isInGalleryPremiumAdoptionVariation = (bucketUser = false) => {
  if (isWebview() || window.isProUser || window.isPartyPassUser) return false; // skip if apps or Evite Pro user or Party Pass user
  return getOrSetVariation('gallery_premium_adoption', 'control', !bucketUser) === 'variant';
};
