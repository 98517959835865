import {createContext, useEffect, useMemo, useRef, useState} from 'react';

const searchCategories = [
  {
    text: 'Invitations',
    urlFilterParam: 'invitation',
  },
  {
    text: 'eCards',
    urlFilterParam: 'card',
  },
];

const defaultContextValue = {
  searchQuery: null,
  setSearchQuery: () => null,
  searchCategories,
  searchCategory: 'Invitations',
  setSearchCategory: () => null,
};

export const SearchContext = createContext(defaultContextValue);

export const SearchContextParent = ({children}) => {
  const [, setMounted] = useState(false);
  const [searchCategory, setSearchCategory] = useState('Invitations');
  const searchQuery = useRef(null);
  const setSearchQuery = (query) => {
    searchQuery.current = query;
    // Forcing a re-render since changing the ref does not trigger it automatically
    setMounted(true);
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  const contextValue = useMemo(
    () => ({
      searchCategories,
      searchCategory,
      setSearchCategory,
      searchQuery,
      setSearchQuery,
    }),
    [searchCategory]
  );

  return <SearchContext.Provider value={contextValue}>{children}</SearchContext.Provider>;
};
