import React, {useEffect, useMemo, useRef, useState} from 'react';
import Select, {components} from 'react-select';
import PropTypes from 'prop-types';
import {
  coin,
  fog,
  silver,
  ghost,
  cottonCandy,
  charcoal,
  emeraldWebText,
  aquamarine,
} from '~sass/pb_styleguide/base/_exports.sass';

import '~/MegaNav/SearchWithCategory/SearchWithCategory.sass';
import {PB_Checkmark} from '~/common/svg/PB_Checkmark';
import {PB_Chevron} from '~/common/svg/PB_Chevron';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';
import {Button} from '../atoms/Button';

const CategoryDropdownIndicator = (dropdownProps) => (
  <components.DropdownIndicator {...dropdownProps} className="select-dropdown">
    <PB_Chevron ratio={0.5} />
  </components.DropdownIndicator>
);
const CategoryPickerValueContainer = (vcProps) => (
  <components.ValueContainer {...vcProps} data-qa-id="meganavSearchCategorySelector" />
);

const Control = ({
  children,
  innerProps: {key},
  selectProps: {categoryPickerDataQaId, setOpen},
  ...props
}) => (
  <components.Control {...props}>
    <Button
      variant="transparent"
      size="large"
      key={key}
      data-qa-id={categoryPickerDataQaId}
      className="select__control"
      onClick={() => setOpen(true)}
    >
      {children}
    </Button>
  </components.Control>
);

export const SearchDropdown = ({
  categories,
  selectedCategory,
  onCategorySwitch,
  categoryPickerDataQaId,
  value,
  isInline,
}) => {
  const {matches: lgWindow} = useMatchQuery('(min-width: 80rem)', []);
  const [active, setActive] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const controlRef = useRef(null);

  // eslint-disable-next-line react/no-unstable-nested-components
  const CategoryOption = (optionProps) => {
    const {label, selectOption, isSelected} = optionProps;
    return (
      <components.Option
        {...optionProps}
        className={`search-select-option${label === categories[0] ? ' no-separator' : ''}`}
        innerProps={{
          'data-qa-id': `searchCategory_${label.replaceAll(' ', '_')}`,
          onClick: () => selectOption(optionProps),
        }}
        data-qa-id={`searchCategory_${label.replaceAll(' ', '_')}`}
      >
        {label}
        {isSelected && <PB_Checkmark ratio={0.5} color={emeraldWebText} />}
      </components.Option>
    );
  };

  useEffect(() => {
    if (controlRef.current) {
      controlRef.current.onControlTouchEnd = () => {
        controlRef.current.userIsDragging = true;
      };
    }
  }, [controlRef]);

  const controlBorderColor = useMemo(() => {
    if (active) {
      return aquamarine;
    }
    if (value) {
      return coin;
    }
    return silver;
  }, [active, value]);

  const selectStyles = useMemo(
    () => ({
      indicatorSeparator: () => ({}),
      container: (provided) => ({
        ...provided,
        minWidth: lgWindow ? '12rem' : '9rem',
        width: lgWindow ? '12rem' : '9rem',
        height: isInline ? '3.5rem' : '3rem',
      }),
      control: (provided, {isDisabled}) => ({
        ...provided,
        height: '100%',
        paddingLeft: '1rem',
        paddingRight: 7,
        fontFamily: 'Brut',
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1.25rem',
        borderColor: controlBorderColor,
        borderWidth: 'thin',
        boxShadow: undefined,
        backgroundColor: isDisabled ? fog : ghost,
        borderRadius: isInline ? '0.5rem' : '0.25rem',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: 'none',
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: 0,
        justifyContent: isInline ? 'center' : undefined,
        flex: isInline ? 'unset' : 1,
      }),
      singleValue: (provided) => ({
        ...provided,
        justifySelf: isInline ? 'center' : 'flex-start',
      }),
      menu: (provided) => ({
        ...provided,
        borderRadius: '0.5rem',
      }),
      menuList: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
        borderRadius: '0.5rem',
      }),
      option: (provided, {isSelected}) => ({
        ...provided,
        fontFamily: 'Brut',
        fontWeight: 400,
        color: isSelected ? emeraldWebText : charcoal,
        backgroundColor: ghost,
        ':hover': {
          backgroundColor: cottonCandy,
        },
        fontSize: '1rem',
        lineHeight: '1.25rem',
      }),
    }),
    [isInline, lgWindow]
  );

  return (
    <div className="search-dropdown">
      <Select
        onChange={(newValue) => onCategorySwitch(newValue.value)}
        isSearchable={false}
        options={categories.map((c) => ({label: c, value: c}))}
        styles={selectStyles}
        components={{
          DropdownIndicator: CategoryDropdownIndicator,
          Option: CategoryOption,
          ValueContainer: CategoryPickerValueContainer,
          Control,
        }}
        ref={controlRef}
        value={{label: selectedCategory, value: selectedCategory}}
        data-qa-id={categoryPickerDataQaId}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        name={`${categoryPickerDataQaId}_value`}
        onMenuClose={() => setOpen(false)}
        menuIsOpen={isOpen}
        onMenuOpen={() => setOpen(true)}
        setOpen={setOpen}
        categoryPickerDataQaId={categoryPickerDataQaId}
      />
    </div>
  );
};

SearchDropdown.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedCategory: PropTypes.string,
  onCategorySwitch: PropTypes.func,
  categoryPickerDataQaId: PropTypes.string,
  isInline: PropTypes.bool,
};
