import {appsignal} from '~/common/global';

export async function logger(message, data, severity = 'info') {
  const loggingApiKey = window.app_signal_api_key;
  if (loggingApiKey && window.app_signal_enabled === 'true') {
    const url = `https://appsignal-endpoint.net/logs/json?api_key=${loggingApiKey}`;
    const ndjson = {
      timestamp: new Date().toISOString(),
      message,
      attributes: data,
      hostname: window.location.hostname,
      severity: severity || 'info',
    };
    try {
      await fetch(url, {
        method: 'POST',
        mode: 'no-cors',
        credentials: 'include',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ndjson),
      });
    } catch (e) {
      appsignal.sendError(e);
      evite.error('Failed to log', e);
    }
  }
}
