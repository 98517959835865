import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const PB_Info = (props) => {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 14;
  const height = 14;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
    >
      <path
        d="M7.00065 13.6668C3.31865 13.6668 0.333984 10.6822 0.333984 7.00016C0.333984 3.31816 3.31865 0.333496 7.00065 0.333496C10.6827 0.333496 13.6673 3.31816 13.6673 7.00016C13.6673 10.6822 10.6827 13.6668 7.00065 13.6668ZM7.00065 12.3335C8.41514 12.3335 9.77169 11.7716 10.7719 10.7714C11.7721 9.7712 12.334 8.41465 12.334 7.00016C12.334 5.58567 11.7721 4.22912 10.7719 3.22893C9.77169 2.22873 8.41514 1.66683 7.00065 1.66683C5.58616 1.66683 4.22961 2.22873 3.22941 3.22893C2.22922 4.22912 1.66732 5.58567 1.66732 7.00016C1.66732 8.41465 2.22922 9.7712 3.22941 10.7714C4.22961 11.7716 5.58616 12.3335 7.00065 12.3335V12.3335ZM6.33398 3.66683H7.66732V5.00016H6.33398V3.66683ZM6.33398 6.3335H7.66732V10.3335H6.33398V6.3335Z"
        fill={color}
      />
    </svg>
  );
};
