import PropTypes from 'prop-types';
import cx from 'classnames';
import {useMemo} from 'react';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {Overlay} from '~/common/_pb_components/atoms/Overlay';
import {Spinner} from '~/common/components/Loader/Spinner';

// Intended for small, single-action content, returns a popup with overlay
// in both desktop & mobile.
// Requires isOpen, onClose, as well as the following:

// Requires either children:
//    (will render children with X close button in upper right in an otherwise empty modal)
// OR: all the below
//  title: (string),
//  body: (string || node),
//  primaryAction: (func called on primary CTA),
//  modalId: (string identifier for this modal)
//  primaryText: (string for primary CTA text)

export const ResponsiveModal = ({
  isOpen,
  body = null,
  children = null,
  onClose,
  primaryAction = onClose,
  secondaryAction = onClose,
  primaryText = 'Submit',
  secondaryText = 'Cancel',
  showSpinner = false,
  actionText = 'One moment please...',
  showSecondary = true,
  modalId = 'modal',
  className = '',
  isModal = false,
  showHeader = true,
  showPrimaryButton = true,
  title = null,
  closeOnClickOutside,
  primaryQaId = null,
  secondaryQaId = null,
  closeQaId = null,
  titleQaId = null,
  bodyQaId = null,
  secondaryImpressionTracking = null,
  primaryImpressionTracking = null,
  equalButtons,
}) => {
  const primaryQaIdToUse = primaryQaId ?? `${modalId}-submit`;
  const secondaryQaIdToUse = secondaryQaId ?? `${modalId}-cancel`;

  const renderedTitle = useMemo(
    () => (showSpinner ? actionText : title || `${primaryText}?` || 'Are you sure?'),
    [showSpinner, actionText, title, primaryText]
  );

  let titleText = null;
  if (showHeader) {
    if (showSpinner) titleText = actionText;
    else titleText = renderedTitle;
  }

  let renderedChildren = showSpinner ? <Spinner center /> : children;

  const getModalChildren = () => (
    <div className={className}>
      <div className="responsive-modal-body">
        {typeof body !== 'string' ? (
          body
        ) : (
          <Typography variant="paragraph2" data-qa-id={bodyQaId ?? ''}>
            {body || ''}
          </Typography>
        )}
      </div>
      <div className="responsive-modal-button-row">
        {showSecondary && (
          <Button
            variant="secondary"
            size="large"
            onClick={secondaryAction}
            data-qa-id={secondaryQaIdToUse}
            data-impression-tracking={secondaryImpressionTracking}
            fullWidth={equalButtons}
          >
            {secondaryText}
          </Button>
        )}
        {showPrimaryButton && (
          <Button
            variant="primary"
            size="large"
            onClick={primaryAction}
            data-qa-id={primaryQaIdToUse}
            data-impression-tracking={primaryImpressionTracking}
            fullWidth={equalButtons}
          >
            {primaryText}
          </Button>
        )}
      </div>
    </div>
  );

  if (!renderedChildren) renderedChildren = getModalChildren();

  return (
    <Overlay
      open={isOpen}
      isModal={isModal}
      onClose={(e) => onClose(e)}
      modalId={cx('responsive-modal', modalId)}
      title={titleText}
      showSpinner={showSpinner}
      closeOnClickOutside={closeOnClickOutside}
      titleQaId={titleQaId}
      closeQaId={closeQaId}
    >
      {renderedChildren}
    </Overlay>
  );
};

ResponsiveModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.node,
  body: PropTypes.node,
  modalId: PropTypes.string,
  primaryAction: PropTypes.func,
  primaryText: PropTypes.string,
  secondaryAction: PropTypes.func,
  secondaryText: PropTypes.string,
  actionText: PropTypes.string,
  showSpinner: PropTypes.bool,
  showHeader: PropTypes.bool,
  showPrimaryButton: PropTypes.bool,
  showSecondary: PropTypes.bool,
  className: PropTypes.string,
  primaryQaId: PropTypes.string,
  secondaryQaId: PropTypes.string,
  closeQaId: PropTypes.string,
  titleQaId: PropTypes.string,
  bodyQaId: PropTypes.string,
  equalButtons: PropTypes.bool,
};
