import {useState} from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {getCategoryHref} from '../utils';
import {Link} from '~/common/_pb_components/atoms/Link';

import './DesktopExpandedCategoryView.sass';

export const DesktopExpandedCategoryView = ({
  type,
  category_id: id,
  href,
  visible,
  navDesignVariationDesktop,
  'data-qa-id': qaId,
  categories,
  category_names,
  display_name,
  display_name_prefix,
}) => {
  const DEFAULT_NUM_TO_SHOW = ['B', 'C'].includes(navDesignVariationDesktop) ? 15 : 4;
  const [seeMore, setSeeMore] = useState(false);

  return (
    <div className={`subcategory redesign-${navDesignVariationDesktop}`}>
      {visible && (
        <Link
          className={cx('paragraph2', 'subcategory-inner-list-item', {
            'subcategory-name-link': href,
            'subcategory-name': !href,
          })}
          data-qa-id={qaId}
          href={href}
        >
          {display_name_prefix ? (
            <>
              {display_name_prefix} <br />
            </>
          ) : null}
          {display_name}
        </Link>
      )}

      {categories
        .slice(0, DEFAULT_NUM_TO_SHOW)
        .map(({name, href: innerHref, display_name: innerDisplayName}) => (
          <a
            key={name || innerDisplayName}
            href={getCategoryHref({name, href: innerHref, id, type})}
            className="paragraph3 subcategory-inner-list-item"
            data-qa-id={`${name}_subcategoryLink`}
            onClick={(e) => {
              const {inVariation: is} = evite.experiments;

              if (is('dyo_uyo_landing', 'landing') && category_names.includes('design_your_own')) {
                e.preventDefault();
                window.location.href = '/upload-your-own';
              }
            }}
          >
            {innerDisplayName}
          </a>
        ))}
      {categories.length > DEFAULT_NUM_TO_SHOW &&
        (seeMore ? (
          categories.slice(DEFAULT_NUM_TO_SHOW).map((c) => (
            <a
              key={c.name || c.display_name}
              href={getCategoryHref(c)}
              className="paragraph3 subcategory-inner-list-item"
              data-qa-id={`${c.name}_subCategoryLink`}
            >
              {c.display_name}
            </a>
          ))
        ) : (
          <button
            type="button"
            onClick={() => setSeeMore(true)}
            className="unstyled-button paragraph3 see-more-link"
            data-qa-id={`${display_name}_subCategory_SeeMoreButton`}
          >
            {categories.length - DEFAULT_NUM_TO_SHOW} More
          </button>
        ))}
    </div>
  );
};

DesktopExpandedCategoryView.propTypes = {
  category_id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  categories: PropTypes.arrayOf(PropTypes.object),
  category_names: PropTypes.arrayOf(PropTypes.string),
  display_name: PropTypes.string,
  visible: PropTypes.bool,
  type: PropTypes.oneOf(['invitation', 'card']).isRequired,
  'data-qa-id': PropTypes.string.isRequired,
  navDesignVariationDesktop: PropTypes.oneOf(['control', 'B', 'C']).isRequired,
  href: PropTypes.string,
};
