import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const PB_FavoritesLine = (props) => {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0014 6.03864C19.1334 3.22664 23.9734 3.31997 26.9908 6.34264C30.0068 9.36664 30.1108 14.1826 27.3054 17.324L15.9988 28.6466L4.69476 17.324C1.88942 14.1826 1.99476 9.35864 5.00942 6.34264C8.02942 3.32397 12.8601 3.22264 16.0014 6.03864ZM25.1028 8.22664C23.1028 6.22397 19.8761 6.14264 17.7828 8.02264L16.0028 9.61997L14.2214 8.02397C12.1214 6.14131 8.90142 6.22397 6.89609 8.22931C4.90942 10.216 4.80942 13.396 6.64009 15.4973L16.0001 24.872L25.3601 15.4986C27.1921 13.396 27.0921 10.22 25.1028 8.22664Z"
        fill={color}
      />
    </svg>
  );
};
