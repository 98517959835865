import {GalleryAssetName} from '~/GalleryPB/utils/constants';

const hrefFabric = (result) =>
  result.premium && (window.cards_page || result.usedAs !== 'invitation')
    ? `/cards/${result.templateId}/create`
    : `/invitation/${result.templateId}/create`;

export const buildHref = (result, tracking, currentPage) => {
  let href = hrefFabric(result);

  const params = new URLSearchParams();

  if (!result.premium && !window.cards_page) {
    // Free templates only
    params.append('template', result.templateId);
    params.append('event_type', window.category_name || result.eventType || '');
  } else if (result.premium) {
    // Premium invites & cards
    params.append('v', result.versionName || '');
  }

  if (window.gallery_query) {
    // Searches get keyword added as query param
    params.append('query', window.gallery_query);
  }

  params.append('c', window.category_name || result.eventType || '');

  const getPreviousParam = () => {
    let previous;

    if (window.is_favorites_page) {
      previous = 'favorites';
    } else if (params.get('query')) {
      previous = 'search';
    } else {
      previous = window.category_name || result.eventType || '';
    }

    return previous;
  };

  params.append('previous', getPreviousParam()); // for "back" buttons bc no time to refactor NavMachine in create/controller.js
  params.append('page', currentPage);

  const filterParams = tracking && tracking.split('&');
  if (filterParams) {
    filterParams.map((f) => {
      const pair = f.split('=');
      params.append(pair[0], pair[1]);
    });
  }

  if (result.pos >= 0) params.append('pos', result.pos);

  href += `?${params.toString()}`;
  return href;
};

const pickSrcFabric = (assets, desktop, useComposed) => {
  const assetSize = desktop ? 'desktop' : 'mobile';
  const templateType = useComposed ? 'composed' : 'standalone';
  return (
    assets[GalleryAssetName[assetSize][templateType]] ||
    assets[GalleryAssetName[assetSize][`${templateType}_legacy`]]
  );
};
export const pickSrc = (result, desktop, useComposed) => {
  const defaultAssets = result.assets?.default;
  const srcResult = defaultAssets ? pickSrcFabric(defaultAssets, desktop, useComposed) : null;

  if (typeof srcResult === 'string') {
    return srcResult;
  }
  if (typeof srcResult === 'object') {
    return srcResult?.http_path;
  }
  return null;
};
