import React from 'react';
import PropTypes from 'prop-types';

import './NotificationsWrapper.sass';
import {PB_NotificationFill} from '~/common/svg/PB_NotificationFill';
import {PB_NotificationLine} from '~/common/svg/PB_NotificationLine';
import {NotificationsView} from '~/MegaNav/NotificationsView/NotificationsView';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';

export const NotificationsWrapper = (props) => {
  /** Media query hooks */
  const {matches: lgWindow} = useMatchQuery('(min-width: 80rem)', []);

  return (
    <div id="pbNotificationsView" className="notifications-container">
      <button
        className="unstyled-button notification-bell-button"
        onClick={props.onNotificationsClick}
        data-qa-id="notificationBellIconButton"
      >
        {props.notificationsObj.notifications &&
        props.notificationsObj.notifications.filter((n) => !n.read).length > 0 ? (
          <PB_NotificationFill ratio={lgWindow ? 0.75 : 0.5} />
        ) : (
          <PB_NotificationLine ratio={lgWindow ? 0.75 : 0.5} />
        )}
      </button>
      <div
        className={`notifications-view-wrapper ${props.notificationsViewOpen ? 'open' : ''} ${
          lgWindow ? `redesign-${props.navDesignVariationDesktop}` : ''
        }`}
        id="notificationsMenu"
      >
        <NotificationsView
          {...props.notificationsObj}
          onMarkAllAsRead={props.onMarkAllAsRead}
          onMarkOneAsRead={props.onMarkOneAsRead}
          onMarkOneAsUnread={props.onMarkOneAsUnread}
          onHideNotification={props.onHideNotification}
        />
      </div>
    </div>
  );
};

NotificationsWrapper.propTypes = {
  notificationsViewOpen: PropTypes.bool,
  onNotificationsViewOpen: PropTypes.func,
  notificationsObj: PropTypes.object.isRequired,
  onMarkAllAsRead: PropTypes.func.isRequired,
  onMarkOneAsRead: PropTypes.func.isRequired,
  onMarkOneAsUnread: PropTypes.func.isRequired,
  onHideNotification: PropTypes.func.isRequired,
  navDesignVariationDesktop: PropTypes.oneOf(['control', 'B', 'C']).isRequired,
};
