// extracted by mini-css-extract-plugin
var _1 = "#709FFF";
var _2 = "#51545A";
var _3 = "#E1E3E6";
var _4 = "#FFECE9";
var _5 = "https://g0.evitecdn.com/";
var _6 = "#101B21";
var _7 = "#FF9548";
var _8 = "#C75300";
var _9 = "#B9BCBF";
var _a = "#92959B";
var _b = "#E2ECFF";
var _c = "#66DB86";
var _d = "#20883C";
var _e = "#28A842";
var _f = "min-width: 80rem";
var _10 = "#FF6348";
var _11 = "#FFECE9";
var _12 = "#FFECE9";
var _13 = "#D62000";
var _14 = "#709FFF";
var _15 = "#E2ECFF";
var _16 = "#E2ECFF";
var _17 = "#2424E5";
var _18 = "#FF9548";
var _19 = "#E092FF";
var _1a = "#B9BCBF";
var _1b = "#66DB86";
var _1c = "#E3FBE4";
var _1d = "#E3FBE4";
var _1e = "#20883C";
var _1f = "#FF9548";
var _20 = "#FFF5ED";
var _21 = "#FFF5ED";
var _22 = "#C75300";
var _23 = "#66DB86";
var _24 = "#F5F5F7";
var _25 = "#B9BCBF";
var _26 = "#B9BCBF";
var _27 = "#FFFFFF";
var _28 = "#FFFFFF";
var _29 = "calc(80rem - 0.1rem)";
var _2a = "min-width: 48rem";
var _2b = "calc(61.876rem - 0.1rem)";
var _2c = "min-width: 61.875rem";
var _2d = "#EBEAFD";
var _2e = "#F5F3F2";
var _2f = "#5352EB";
var _30 = "#5352EB";
var _31 = "#5352EB";
var _32 = "calc(48rem - 0.1rem)";
var _33 = "min-width: 31.25rem";
var _34 = "rgba(106, 110, 113, 0.2)";
var _35 = "#FF6348";
var _36 = "#D62000";
var _37 = "#E3FBE4";
var _38 = "rgba(227, 251, 228, 0.5)";
var _39 = "#6A6E71";
var _3a = "#92959B";
var _3b = "#6A6E71";
var _3c = "#FFFFFF";
var _3d = "#C10097";
var _3e = "#E092FF";
var _3f = "#C10097";
var _40 = "#FF9548";
var _41 = "#E092FF";
var _42 = "#66DB86";
var _43 = "#2E3136";
var _44 = "#E1E3E6";
var _45 = "calc(31.25rem - 0.1rem)";
var _46 = "min-width: 23.375rem";
var _47 = "clamp(2.5rem, 11vw, 5rem)";
var _48 = "clamp(1.5rem, 5vw, 3rem)";
var _49 = "clamp(1rem, 3vw, 1.5rem)";
var _4a = "#5352EB";
var _4b = "#2424E5";
var _4c = "#CA6E2E";
var _4d = "#E2ECFF";
var _4e = "#101B21";
var _4f = "#FFF5ED";
var _50 = "#F4E2D5";
var _51 = "#FFFFFF";
export { _1 as "aquamarine", _2 as "asphalt", _3 as "borderColor", _4 as "bubbleGum", _5 as "cdnPath", _6 as "charcoal", _7 as "citrine", _8 as "citrineWebText", _9 as "cloud", _a as "coin", _b as "cottonCandy", _c as "emerald", _d as "emeraldWebText", _e as "eviteGreen", _f as "extraLargeAndUpQuery", _10 as "feedbackError", _11 as "feedbackErrorBg", _12 as "feedbackErrorSubtle", _13 as "feedbackErrorText", _14 as "feedbackInformation", _15 as "feedbackInformationBg", _16 as "feedbackInformationSuble", _17 as "feedbackInformationText", _18 as "feedbackMaybe", _19 as "feedbackNo", _1a as "feedbackNoReply", _1b as "feedbackSuccess", _1c as "feedbackSuccessBg", _1d as "feedbackSuccessSubtle", _1e as "feedbackSuccessText", _1f as "feedbackWarning", _20 as "feedbackWarningBg", _21 as "feedbackWarningSubtle", _22 as "feedbackWarningText", _23 as "feedbackYes", _24 as "fog", _25 as "formBorder", _26 as "formBorderDisabled", _27 as "formInputBg", _28 as "ghost", _29 as "largeAndUp", _2a as "largeAndUpQuery", _2b as "largishAndUp", _2c as "largishAndUpQuery", _2d as "lavender", _2e as "linen", _2f as "link", _30 as "linkColor", _31 as "linkHoverColor", _32 as "mediumAndUp", _33 as "mediumAndUpQuery", _34 as "menuHover", _35 as "mexicanOpal", _36 as "mexicanOpalWebText", _37 as "mintChip", _38 as "mintChipLight", _39 as "pebble", _3a as "placeholder", _3b as "popoverColor", _3c as "popoverTextColor", _3d as "premiumColor", _3e as "rose", _3f as "roseWebText", _40 as "rsvpMaybe", _41 as "rsvpNo", _42 as "rsvpYes", _43 as "shadow", _44 as "silver", _45 as "smallAndUp", _46 as "smallAndUpQuery", _47 as "spacingLg", _48 as "spacingMd", _49 as "spacingSm", _4a as "tanzanite", _4b as "tanzaniteWebText", _4c as "tigersEye", _4d as "tooltipColor", _4e as "tooltipTextColor", _4f as "vanilla", _50 as "waffle", _51 as "white" }
