export class SearchSuggestionModel {
  static get TYPE_CATEGORY() {
    return 'category';
  }

  static get TYPE_SUGGESTION() {
    return 'suggestion';
  }

  static get TYPE_HINT() {
    return 'hint';
  }

  static get TYPE_TEMPLATE() {
    return 'template';
  }

  constructor({id, text, type, src, data}) {
    this.id = id || '';
    this.type = type || '';
    this.text = text || '';
    this.src = src || '';
    this.data = data || {};
  }
}
