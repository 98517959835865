import {Typography} from '~/common/_pb_components/atoms/Typography';
import './NewCategoryTag.sass';

export const NewCategoryTag = () => (
  <div className="new-category-tag">
    <Typography color="white" variant="label2">
      New
    </Typography>
  </div>
);
