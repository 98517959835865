import urls from 'urls';
import {saveUserDataToLocalStorage} from '~/common/auth/utils';

const checkAuthenticated = async () => {
  try {
    const response = await window.evite.fetch(urls.get('ajax_authenticated'), {
      credentials: 'include',
      headers: {
        'X-CSRFToken': evite.cookie('csrftoken'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();

    if (data && data.full_name) {
      saveUserDataToLocalStorage(data);
      return data;
    }
    return null;
  } catch (error) {
    localStorage.removeItem('userData');
    evite.error(`error: ${error}`);
    return null;
  }
};

const waitAuthenticated = (popup) => {
  const waitPopup = (accept, reject) => {
    const checkPopup = async () => {
      if (popup.closed) {
        const user = await checkAuthenticated();
        if (!user) {
          localStorage.removeItem('userData');
          reject();
          return;
        }
        saveUserDataToLocalStorage(user);
        accept(user);
      } else {
        setTimeout(checkPopup, 100);
      }
    };
    checkPopup();
  };

  return waitPopup;
};

export const googlePlusConnect = async () => {
  window.tags.page_event('google_plus_connect');
  const w = 580;
  const h = 400;
  const left = screen.width / 2 - w / 2;
  const top = screen.height / 2 - h / 2;
  const popup = window.open(
    urls.get('google_plus_connect'),
    'gp_auth',
    `width=${w},height=${h},top=${top},left=${left}`
  );
  if (popup == null) {
    alert(
      'We tried to open a popup window to connect to Google Plus. Please enable popup windows in your browser.'
    );
    return null;
  }

  try {
    return await new Promise(waitAuthenticated(popup));
  } catch (error) {
    console.log('popup closed without logging in');
    return null;
  }
};

export const appleConnect = async () => {
  const w = 580;
  const h = 400;
  const left = screen.width / 2 - w / 2;
  const top = screen.height / 2 - h / 2;
  const popup = window.open(
    urls.get('apple_connect'),
    'apple_auth',
    `width=${w},height=${h},top=${top},left=${left}`
  );

  if (popup == null) {
    alert(
      'We tried to open a popup window to connect to Apple. Please enable popup windows in your browser.'
    );
    return null;
  }

  try {
    return await new Promise(waitAuthenticated(popup));
  } catch (error) {
    console.log('popup closed without logging in');
    return null;
  }
};
