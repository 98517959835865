import * as constants from './constants';

export const showLoginModal = () => ({
  type: constants.SHOW_LOGIN_MODAL,
});

export const hideLoginModal = () => ({
  type: constants.HIDE_LOGIN_MODAL,
});

export const userLoggedIn = (
  userId,
  userName,
  subscriptionPlanName,
  email,
  avatarDisk,
  imgUrl,
  initials,
  firstName
) => ({
  type: constants.USER_LOGGED_IN,
  userId,
  userName,
  subscriptionPlanName,
  email,
  avatarDisk,
  imgUrl,
  initials,
  firstName,
});
