import React from 'react';
import PropTypes from 'prop-types';

import './AcctDrawerItem.sass';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';
import {Typography} from '~/common/_pb_components/atoms/Typography';

export const AcctDrawerItem = (props) => {
  const {matches: lgWindow} = useMatchQuery('(min-width: 80rem)', []);

  return props.type === 'button' ? (
    <button
      className="unstyled-button acct-details-drawer__cta"
      onClick={props.onClick}
      tabIndex={props.tabIndex}
    >
      {props.icon}
      <Typography
        as="p"
        variant={lgWindow ? 'paragraph2' : 'paragraph1'}
        data-qa-id={props['data-qa-id']}
      >
        {props.text}
      </Typography>
    </button>
  ) : (
    <a className="acct-details-drawer__cta" href={props.href} tabIndex={props.tabIndex}>
      {props.icon}
      <Typography
        as="p"
        variant={lgWindow ? 'paragraph2' : 'paragraph1'}
        data-qa-id={props['data-qa-id']}
      >
        {props.text}
      </Typography>
    </a>
  );
};

AcctDrawerItem.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['button', 'link']),
  tabIndex: PropTypes.number,
  'data-qa-id': PropTypes.string.isRequired,
};
