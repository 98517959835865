import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const PB_Arrow = (props) => {
  const {color = charcoal, className = '', ratio = 1, direction = 'right'} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  const rotateFromRight = (direction) => {
    switch (direction) {
      case 'right':
        return 0;
      case 'left':
        return 180;
      case 'down':
        return 90;
      case 'up':
        return 270;
    }
  };

  // Use ratio to calculate width and height
  const style = {
    transform: `rotate( ${rotateFromRight(direction)}deg)`,
    height: `${ratio * height}px`,
    width: `${ratio * width}px`,
  };

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5626 14.6666L14.4106 7.51458L16.296 5.62924L26.6666 15.9999L16.296 26.3706L14.4106 24.4852L21.5626 17.3332H5.33331V14.6666H21.5626Z"
        fill={color}
      />
    </svg>
  );
};
