import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const PB_Premium = (props) => {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 180;
  const height = 180;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.3751 16.875C38.3314 16.875 37.3083 17.1655 36.4203 17.7138C35.5322 18.2621 34.8143 19.0468 34.3467 19.9799L11.8483 64.9777C11.3617 65.9465 11.1655 67.0353 11.2833 68.113C11.401 69.1907 11.8277 70.2115 12.512 71.0524L85.6318 161.048C86.1592 161.697 86.8246 162.22 87.5797 162.579C88.3347 162.939 89.1603 163.125 89.9965 163.125C90.8327 163.125 91.6583 162.939 92.4133 162.579C93.1684 162.22 93.8338 161.697 94.3612 161.048L167.481 71.0524C168.167 70.2126 168.596 69.1925 168.716 68.1147C168.835 67.0369 168.641 65.9475 168.156 64.9777L145.658 19.9799C145.189 19.045 144.469 18.2593 143.579 17.7109C142.689 17.1624 141.663 16.873 140.618 16.875H39.3751ZM25.9773 61.8728L42.8511 28.1245H65.912L57.4751 61.8728H25.9773ZM28.6884 73.1223H57.745L75.89 131.214L28.6884 73.1223ZM69.523 73.1223H110.47L89.9965 138.628L69.523 73.1223ZM122.259 73.1223H151.305L104.103 131.214L122.259 73.1223ZM154.016 61.8728H122.518L114.081 28.1245H137.142L154.016 61.8728ZM110.92 61.8728H69.073L77.5099 28.1245H102.483L110.92 61.8728Z"
        fill={color}
      />
    </svg>
  );
};
