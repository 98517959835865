import React from 'react';
import {black} from '~sass/reinvention_styleguide/base/_exports.sass';

export const PB_Trending = (props) => {
  const {color = black, className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg viewBox={`0 0 ${width} ${height}`} style={style} className={className}>
      <path
        d="M4.53332 24L2.66666 22.1333L12.5333 12.2L17.8667 17.5333L24.8 10.6667H21.3333V8H29.3333V16H26.6667V12.5333L17.8667 21.3333L12.5333 16L4.53332 24Z"
        fill={color}
      />
    </svg>
  );
};
