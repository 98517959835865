import React, {useState} from 'react';
import PropTypes from 'prop-types';
import urls from 'urls';

import './LoginContainer.sass';
import {PB_CloseLine} from '~/common/svg/PB_CloseLine';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Input} from '~/common/_pb_components/atoms/Input';
import {Overlay} from '~/common/_pb_components/atoms/Overlay';
import {Divider} from '~/common/_pb_components/atoms/Divider';
import {Link} from '~/common/_pb_components/atoms/Link';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';
import {validateEmail} from '~/ViewInvitation/utils/validateEmail';
import {saveUserDataToLocalStorage, submitLoginWithCaptcha} from '~/common/auth/utils';

import {tanzanite} from '~sass/pb_styleguide/base/_exports.sass';

export const LoginContainer = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginFailure, setLoginFailure] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [forgotPasswordSentModalOpen, setForgotPasswordSentModalOpen] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const complete = params.get('complete');
  const showHostMessage = ['event', 'invite_more', 'view_host'].includes(complete);

  const trackUserId = (userId, subscription_plan_name, email) => {
    window.dataLayer.push({user_identifier: userId});
    window.dataLayer.push({is_logged_in: 1});
    if (subscription_plan_name) window.dataLayer.push({subscription_plan_name});
    window.dataLayer.push({email});
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setEmailError('');
    const {valid, value} = validateEmail(email, false);

    if (!valid || password.length < 6) {
      setLoginFailure(true);
    } else {
      try {
        // Attempt login
        const resp = await submitLoginWithCaptcha({email, password});
        if (resp?.data) {
          // login success
          const {data} = resp;
          const userId = data.user_id;
          setLoginFailure(false);
          trackUserId(userId, data.subscription_plan_name, data.email);
          saveUserDataToLocalStorage(data);
          props.onSuccess(
            userId,
            data.full_name,
            data.subscription_plan_name,
            data.email,
            data.avatar_disk,
            data.image_url,
            data.initials,
            data.first_name
          );
        }
      } catch (err) {
        localStorage.removeItem('userData');
        const errorMsg = err?.xhr?.responseText;
        if (errorMsg === 'Password update required') {
          props.handlePwReset(true);
        } else {
          setLoginFailure(true);
        }
      }
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    e.stopPropagation();
    $.ajax({
      url: '/modal/forgot',
      data: {
        email_forgot: email,
      },
      dataType: 'json',
      type: 'POST',
      success: () => {
        if (window.mobile) {
          alert(
            `If an account matching ${email} is found, you should receive an email with instructions for resetting your password shortly.`
          );
          window.location = '/';
        } else {
          setForgotPasswordSentModalOpen(true);
        }
      },
      error: (xhr) => {
        try {
          if (xhr.status === 429) {
            setEmailError('Too many requests, try again later.');
            return;
          }
          const response = JSON.parse(xhr.responseText);
          setEmailError(response.__all__[0]);
        } catch (err) {
          // TODO: Show some sort of error message to the user?
          console.error(err);
        }
      },
    });
  };

  const hideForgotPasswordSentModal = () => {
    setForgotPasswordSentModalOpen(false);
  };

  const {matches: largeScreen} = useMatchQuery('(min-width: 80rem)');

  return (
    <div className="meganav-login-modal">
      <div className="logo-header">
        {!props.isModal ? (
          <a
            id="logo_top"
            href="/"
            className="logo evite-logo"
            data-qa-id="evite-logo"
            aria-label="evite home"
          />
        ) : (
          <span className="label1 large">
            {props.forgotPasswordMode ? 'Forgot Your Password?' : 'Sign In'}
          </span>
        )}
        {props.isModal && (
          <button
            className="unstyled-button"
            onClick={props.onCloseModal}
            data-qa-id="signupModalCloseButton"
          >
            <PB_CloseLine />
          </button>
        )}
      </div>
      {!props.isModal && showHostMessage && !props.forgotPasswordMode && (
        <>
          <Typography variant="header3">Are you a guest of this event?</Typography>
          <Typography variant="paragraph3">
            <Link data-qa-id="sign-in-link" href={urls.get('view', {event_id: window.event_id})}>
              Click here
            </Link>{' '}
            to view &ldquo;{window.event_title}&rdquo;.
          </Typography>
          <Divider />
          <Typography variant="header3">Are you the host?</Typography>
          <Typography variant="paragraph3">
            Sign in to your Evite account to manage your event!
          </Typography>
        </>
      )}

      <div className="modal-body">
        {!props.forgotPasswordMode ? (
          <>
            {!props.lgWindow || props.isModal ? (
              <Typography
                as="h2"
                variant={largeScreen ? 'header1' : 'header2'}
                data-qa-id="loginModalSignInHeaderText"
              >
                Welcome back! Sign in to your account.
              </Typography>
            ) : (
              ''
            )}
            <div className="social-login-group">
              <Button
                variant="secondary"
                size="large"
                onClick={props.onGoogleLoginClick}
                id="google"
                data-qa-id="loginModalSocialGoogleLoginButton"
              >
                <span />
                Continue with Google
              </Button>
              <Button
                variant="secondary"
                size="large"
                onClick={props.onAppleLoginClick}
                id="apple"
                data-qa-id="loginModalSocialAppleLoginButton"
              >
                <span />
                Continue with Apple
              </Button>
            </div>
          </>
        ) : (
          <Typography as="p" variant="paragraph1" data-qa-id="forgotPasswordGuidingText">
            Enter your email address below and we will send you an email to reset your password.
          </Typography>
        )}
        <form
          className="login-form"
          onSubmit={props.forgotPasswordMode ? handleForgotPassword : handleLogin}
        >
          {!props.forgotPasswordMode && (
            <Typography
              as="label"
              variant="label2"
              classNames="small"
              data-qa-id="orSignInWithEmailText"
            >
              Or Sign In with Email
            </Typography>
          )}
          <Input
            id="login_modal_email_input"
            value={email}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email address"
            error={props.forgotPasswordMode && emailError}
            data-qa-id="loginModalEmailInput"
            autoComplete="email"
          />
          {!props.forgotPasswordMode && (
            <Input
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              error={loginFailure ? 'Invalid email or password. Try again.' : ''}
              data-qa-id="loginModalPasswordInput"
              autoComplete="password"
            />
          )}
          {props.forgotPasswordMode ? (
            <div className="forgot-password__cta-group">
              <Button
                variant="primary"
                size="large"
                type="submit"
                data-qa-id="sendResetEmailButton"
              >
                Send
              </Button>
              <Button
                variant="secondary"
                size="large"
                onClick={(e) => {
                  setEmailError('');
                  props.onForgotPasswordCancel(e);
                }}
                data-qa-id="cancelForgotPasswordButton"
              >
                Cancel
              </Button>
            </div>
          ) : (
            <>
              <Button
                variant="primary"
                size="large"
                type="submit"
                data-qa-id="loginModalSignInButton"
              >
                Sign in
              </Button>
              <div className="forgot-password-wrapper">
                <button
                  onClick={(e) => {
                    setEmailError('');
                    props.onForgotPasswordClick(e);
                  }}
                  className="unstyled-button"
                  data-qa-id="loginModalForgotPasswordButton"
                >
                  <Typography
                    as="p"
                    variant="link"
                    classNames="large"
                    data-qa-id="loginModalForgotPasswordButtonText"
                  >
                    Forgot password?
                  </Typography>
                </button>
              </div>
            </>
          )}
        </form>
        {!props.forgotPasswordMode && (
          <Typography
            as="p"
            variant="paragraph2"
            classNames="sign-in-redirect"
            data-qa-id="loginModalDontHaveAccountText"
          >
            Don&apos;t have an account?{' '}
            <button
              className="unstyled-button link"
              onClick={props.onCreateAccountClick}
              data-qa-id="loginModalGoToCreateAccountButton"
            >
              Create one
            </button>
          </Typography>
        )}
        {props.forgotPasswordMode && (
          <Overlay open={forgotPasswordSentModalOpen} onClose={hideForgotPasswordSentModal}>
            <div className="forgot-password__sent-modal">
              <div className="forgot-password__sent-modal__header">
                <Typography as="h2" variant="header2" data-qa-id="forgotPasswordSentHeaderText">
                  Forgot Your Password?
                </Typography>
              </div>
              <Typography as="p" variant="paragraph2" data-qa-id="resetEmailSentGuidingText">
                If an account matching <span style={{color: tanzanite}}>{email}</span> is found, you
                should receive an email with instructions for resetting your password shortly.
              </Typography>
              <Button
                id="closeForgotPasswordSentModalBtn"
                variant="primary"
                size="large"
                onClick={hideForgotPasswordSentModal}
                data-qa-id="hideForgotPasswordSentModalButton"
              >
                Close
              </Button>
            </div>
          </Overlay>
        )}
      </div>
    </div>
  );
};

LoginContainer.propTypes = {
  onCloseModal: PropTypes.func,
  lgWindow: PropTypes.bool,
  onSuccess: PropTypes.func,
  onForgotPasswordClick: PropTypes.func,
  onForgotPasswordCancel: PropTypes.func,
  onCreateAccountClick: PropTypes.func,
  onGoogleLoginClick: PropTypes.func,
  onFacebookLoginClick: PropTypes.func,
  onAppleLoginClick: PropTypes.func,
  forgotPasswordMode: PropTypes.bool,
};
