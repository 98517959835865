import * as constants from './constants';

const initialState = {
  showLoginModal: false,
};

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.USER_LOGGED_IN:
    case constants.HIDE_LOGIN_MODAL: {
      return {
        showLoginModal: false,
      };
    }
    case constants.SHOW_LOGIN_MODAL: {
      return {
        showLoginModal: true,
      };
    }
    default: {
      return state;
    }
  }
};
