import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const PB_NotificationLine = (props) => {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66667 24H25.3333V14.708C25.3333 9.53066 21.1547 5.33333 16 5.33333C10.8453 5.33333 6.66667 9.53066 6.66667 14.708V24ZM16 2.66666C22.6267 2.66666 28 8.05733 28 14.708V26.6667H4V14.708C4 8.05733 9.37333 2.66666 16 2.66666ZM12.6667 28H19.3333C19.3333 28.8841 18.9821 29.7319 18.357 30.357C17.7319 30.9821 16.8841 31.3333 16 31.3333C15.1159 31.3333 14.2681 30.9821 13.643 30.357C13.0179 29.7319 12.6667 28.8841 12.6667 28Z"
        fill={color}
      />
    </svg>
  );
};
