// Default TTL value in milliseconds, e.g., 1 hour = 60 * 60 * 1000
const DEFAULT_TTL = 60 * 60 * 1000;

/**
 * Fetch content from the given URL and store it in the localStorage.
 * @param {string} url - The API URL to fetch content from.
 * @param {string} localStorageKey - The key used to store the content in localStorage.
 * @param {Object} [urlOptions={}] - Optional fetch options, e.g., headers or method.
 */
async function fetchContent(url, localStorageKey, urlOptions = {}) {
  const response = await window.evite.fetch(url, urlOptions);
  const content = await response.json();

  // Save the content and the current timestamp to localStorage
  const contentObj = {
    content,
    timestamp: Date.now(),
  };
  localStorage.setItem(localStorageKey, JSON.stringify(contentObj));
}

/**
 * Get the content from localStorage.
 * @param {string} localStorageKey - The key used to store the content in localStorage.
 * @returns {Object} - The content retrieved from localStorage.
 */
export const getLocalStorageContent = (localStorageKey) => {
  const contentObj = JSON.parse(localStorage.getItem(localStorageKey));
  return contentObj ? contentObj.content : null;
};

/**
 * Fetch new content from the API and update localStorage if the cache time has expired
 * or window.location includes the _precache_clear parameter.
 * @param {string} url - The API URL to fetch content from.
 * @param {string} localStorageKey - The key used to store the content in localStorage.
 * @param {Object} [urlOptions={}] - Optional fetch options, e.g., headers or method.
 * @returns {Promise<Object>} - The content fetched from the API.
 */
export const getNewContent = async (url, localStorageKey, urlOptions = {}) => {
  const timestamp = JSON.parse(localStorage.getItem(localStorageKey))?.timestamp;
  const clearCache = window.location.href.includes('_precache_clear');
  if (!timestamp || Date.now() - timestamp > DEFAULT_TTL || clearCache) {
    await fetchContent(url + (clearCache ? `?${Date.now()}` : ''), localStorageKey, urlOptions);
    return JSON.parse(localStorage.getItem(localStorageKey)).content;
  }

  return null;
};
