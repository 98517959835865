export const PACKAGE_ID_EVITE_PRO = 'evite_pro_yearly';
export const PACKAGE_ID_GREETING_CARD_YEARLY = 'greeting_card_subscription_annual';
export const PLAN_NAME_GREETING_CARD_SUBSCRIPTION = 'greeting_card_subscription';
export const PLAN_NAME_PARTY_PASS = 'party_pass';
export const PLAN_NAME_PRO = 'pro';
export const PLAN_NAME_PREMIUM_PASS = 'premium_pass';
export const PLAN_NAME_PREMIUM_PASS_SUBSCRIPTION = 'premium_pass_subscription';
export const PAYMENT_TYPE_CREDIT = 'credit';
export const PAYMENT_TYPE_PAYPAL = 'paypal';
export const SUBSCRIPTION_STATUS_ACTIVE = 'active';
export const EVITE_PRO_NAME = 'Evite Pro';
export const GREETING_CARD_SUBSCRIPTION_NAME = 'Greeting Card';
export const PREMIUM_PASS_NAME = 'Premium Pass';
export const PARTY_PASS_NAME = 'Party Pass';
export const FRIENDLY_NAMES = {
  [PACKAGE_ID_EVITE_PRO]: EVITE_PRO_NAME,
  [PLAN_NAME_PRO]: EVITE_PRO_NAME,
  [PLAN_NAME_PARTY_PASS]: PARTY_PASS_NAME,
  [PLAN_NAME_PREMIUM_PASS]: PREMIUM_PASS_NAME,
  [PLAN_NAME_PREMIUM_PASS_SUBSCRIPTION]: PREMIUM_PASS_NAME,
  [PACKAGE_ID_GREETING_CARD_YEARLY]: GREETING_CARD_SUBSCRIPTION_NAME,
  [PLAN_NAME_GREETING_CARD_SUBSCRIPTION]: GREETING_CARD_SUBSCRIPTION_NAME,
};
