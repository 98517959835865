/** Global imports */
import React, {useContext, useEffect} from 'react';
import {createPortal} from 'react-dom';
import {createRoot} from 'react-dom/client';

/** Local imports */
import {AutoCompleteSearchBar} from '~/common/_pb_components/molecules/AutoCompleteSearchBar';
import {SearchDropdown} from '~/common/_pb_components/molecules/SearchDropdown';
import {SearchModel} from '~/common/models/SearchModel';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {PB_Trending} from '~/common/svg/PB_Trending';
import {SearchContext, SearchContextParent} from './SearchContext';
import {useShouldShowInlineSearch} from '../utils';
import {buildHref} from '~/GalleryPB/utils';
import {SearchSuggestionModel} from '~/common/models/SearchSuggestionModel';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';
import {mediumAndUpQuery} from '~sass/pb_styleguide/base/_exports.sass';
import './SearchWithCategory.sass';

/** Constants */
const customClassNames = {
  form: 'search-bar__form',
  input: 'search-bar__input',
  inputWrapperPrefix: 'search-bar__input--prefix-wrapper',
  inputWrapper: 'search-bar__input--wrapper',
  inputWrapperSuffix: 'search-bar__input--suffix-wrapper',
  list: 'search-bar__panel--list',
  item: 'search-bar__panel--item',
  panel: 'search-bar__panel',
  panelLayout: 'search-bar__panel--layout',
  sourceHeader: '',
  clearButton: 'search-bar__input--icons',
  submitButton: 'search-bar__input--icons',
  loadingIndicator: 'search-bar__input--icons',
  trendingIcon: 'search-bar__panel--item-icon-trending',
};
const MIN_SEARCH_SUGGESTION_QUERY_SIZE = 0;
const DEBOUNCING_DELAY_IN_MILLISECONDS = 400;

/** Helper components */
export const SuggestionItem = (props) => {
  const {displayName} = props;
  return (
    <div className="search-bar__panel--item-suggestion">
      <Typography variant="paragraph3" classNames="search-bar__panel--item-title">
        {displayName}
      </Typography>
      <button
        data-qa-id="search-bar__panel--item-button"
        className="search-bar__panel--item-button"
        type="button"
        title="Select"
        aria-label="Select"
      />
    </div>
  );
};

export const TemplateSuggestionItem = (props) => {
  const {id, displayName, src, data} = props;
  return (
    <div className="search-bar__panel--item-template" data-qa-id="search-bar__panel--item-template">
      <img
        className="search-bar__panel--item-template-img"
        src={src}
        alt=""
        data-qa-id="search-bar__panel--item-template-img"
      />
      <Typography
        variant="paragraph3"
        classNames="search-bar__panel--item-template-title"
        data-qa-id="search-bar__panel--item-template-title"
      >
        {displayName}
      </Typography>
      <button
        data-qa-id="search-bar__panel--item-button"
        className="search-bar__panel--item-button"
        type="button"
        title="Select"
        data-ecom-category={`${data.premium ? 'Premium' : 'Free'}/${
          data.usedAs !== 'invitation' ? 'Card' : 'Invitation'
        }/${data.eventType}`}
        data-ecom-name={displayName}
        data-ecom-position="1"
        data-ecom-id={id}
        aria-label="Select"
      />
    </div>
  );
};

export const TrendingSuggestionItem = (props) => {
  const {displayName} = props;
  return (
    <div className="search-bar__panel--item-suggestion">
      <PB_Trending ratio={0.7} className={customClassNames.trendingIcon} />
      <Typography variant="paragraph3" classNames="search-bar__panel--item-title">
        {displayName}
      </Typography>
      <button
        data-qa-id="search-bar__panel--item-button"
        className="search-bar__panel--item-button"
        type="button"
        title="Select"
        aria-label="Select"
      />
    </div>
  );
};

/** Main component */
export const SearchWithCategory = ({isInline}) => {
  const {searchCategories, searchQuery, setSearchQuery, searchCategory, setSearchCategory} =
    useContext(SearchContext);

  const {matches: mediumScreens} = useMatchQuery(`(${mediumAndUpQuery})`);

  /** mobx search model for memoizing the "getting suggestions" ajax calls */
  let searchModel = new SearchModel();

  /** useEffect hooks */
  useEffect(() => {
    /** Populate search query and search category (Invitations or Greeting Cards) based on URL params */

    // If the user has an in-progress search query (i.e. search container got moved to another spot on the page), just pick up where they left off
    if (searchQuery.current !== null) return;

    const urlParams = new URLSearchParams(window.location.search);
    const query = urlParams.get('query');
    const queryTypeExists = urlParams.has('queryType');
    const filter = urlParams.get('filter');
    if (query && !queryTypeExists) {
      setSearchQuery(query);
    }
    if (filter) {
      const category = searchCategories.find((sc) => sc.urlFilterParam === filter);
      if (category) setSearchCategory(category.text);
    }
  }, []);

  /** Debounce function for delaying sending suggestions to the backend * */
  const debouncePromise = (fn, time) => {
    let timerId;

    return function debounced(...args) {
      if (timerId) {
        clearTimeout(timerId);
      }

      return new Promise((resolve) => {
        timerId = setTimeout(() => resolve(fn(...args)), time);
      });
    };
  };
  const debounced = debouncePromise(
    (items) => Promise.resolve(items),
    DEBOUNCING_DELAY_IN_MILLISECONDS
  );

  /** Handle clicking a search suggestion in the searchbar */
  const onSuggestionItemClick = (item) => {
    const chosenCategory = searchCategories.find((c) => c.text === searchCategory);
    const params = new URLSearchParams();
    params.append('filter', chosenCategory?.urlFilterParam || 'invitation');

    if (item.type === SearchSuggestionModel.TYPE_CATEGORY) {
      window.location.href = `${window.location.origin}/gallery/category/${
        item.id
      }/?${params.toString()}`;
    } else if (item.type === SearchSuggestionModel.TYPE_TEMPLATE) {
      window.location.href = buildHref(item.data, searchModel.tracking, 1);
    } else if (item.type === SearchSuggestionModel.TYPE_SUGGESTION) {
      params.append('query', item.id);
      window.location.href = `${window.location.origin}/gallery/search/?${params.toString()}`;
    }
  };

  /** Get search term and category suggestions */
  const getSuggestionsAndCategoriesFromQuery = async (query) => {
    const model = searchModel;
    let categories = [];
    let suggestions = [];
    let templates = [];
    try {
      const filter = searchCategories.find((sc) => sc.text === searchCategory);
      const json = await model.fetchSearchTerm(query, filter?.urlFilterParam);
      if (json && (query === json.search || (!query && !json.search))) {
        await model.sync(json);
      }
      categories = model.categories;
      suggestions = model.suggestions;
      templates = model.templates;
      searchModel = model;
    } catch (err) {
      evite.error(err);
    }
    setSearchQuery(query);
    return {
      categories,
      suggestions,
      templates,
    };
  };

  /** Sources */
  const suggestionsSource = (query, title, searchType, innerOnSuggestionItemClick) => ({
    sourceId: searchType,
    getItems() {
      return getSuggestionsAndCategoriesFromQuery(query).then((result) => {
        if (searchType === SearchSuggestionModel.TYPE_CATEGORY) {
          return result.categories;
        }
        if (searchType === SearchSuggestionModel.TYPE_TEMPLATE) {
          return result.templates;
        }
        return result.suggestions;
      });
    },
    templates: {
      header({items}) {
        if (items.length === 0 || !title) {
          return null;
        }
        return (
          <Typography variant="paragraph4" classNames="search-bar__panel--source-header">
            {title}
          </Typography>
        );
      },
      item({item}) {
        const displayName = item.text;
        if (!query || query.trim() === '') {
          return <TrendingSuggestionItem displayName={displayName} />;
        }
        if (searchType === SearchSuggestionModel.TYPE_SUGGESTION) {
          return <SuggestionItem displayName={displayName} />;
        }
        if (searchType === SearchSuggestionModel.TYPE_TEMPLATE) {
          return (
            <TemplateSuggestionItem
              id={item.id}
              displayName={displayName}
              src={item.src}
              data={item.data}
            />
          );
        }
        return null;
      },
    },
    onSelect({item}) {
      return innerOnSuggestionItemClick(item);
    },
  });

  const determineIfInline = (key) => customClassNames[key].concat(isInline ? ' inline' : '');
  return (
    <div className={`search-with-category ${isInline ? 'inline' : ''}`}>
      <SearchDropdown
        selectedCategory={searchCategory}
        categories={searchCategories.map((c) => c.text)}
        onCategorySwitch={setSearchCategory}
        categoryPickerDataQaId="category-picker"
        isInline={isInline}
      />
      <div className="search-bar__wrapper">
        <AutoCompleteSearchBar
          initialState={{query: searchQuery.current}}
          classNames={{
            ...customClassNames,
            form: determineIfInline('form'),
            inputWrapperPrefix: determineIfInline('inputWrapperPrefix'),
            inputWrapperSuffix: determineIfInline('inputWrapperSuffix'),
            inputWrapper: determineIfInline('inputWrapper'),
            submitButton: determineIfInline('submitButton'),
          }}
          panelContainer="#autocomplete-panel"
          openOnFocus
          onSubmit={({state}) => {
            if (state.query === '' || state.query === null) return;
            onSuggestionItemClick(
              new SearchSuggestionModel({
                id: state.query,
                text: state.query,
                type: SearchSuggestionModel.TYPE_SUGGESTION,
              })
            );
          }}
          placeholder={`Search designs${isInline ? ', themes or categories' : ''}`}
          getSources={async ({query}) => {
            const formattedQuery = query?.toLowerCase();
            if (formattedQuery?.split(' ').join('').length < MIN_SEARCH_SUGGESTION_QUERY_SIZE) {
              return [];
            }
            const productsSuggestionTitle = searchCategory === 'eCards' ? 'eCards' : 'Invitations';
            if (mediumScreens) {
              return debounced([
                suggestionsSource(
                  formattedQuery,
                  '',
                  SearchSuggestionModel.TYPE_SUGGESTION,
                  onSuggestionItemClick
                ),
                suggestionsSource(
                  formattedQuery,
                  productsSuggestionTitle,
                  SearchSuggestionModel.TYPE_TEMPLATE,
                  onSuggestionItemClick
                ),
              ]);
            }
            return debounced([
              suggestionsSource(
                formattedQuery,
                '',
                SearchSuggestionModel.TYPE_SUGGESTION,
                onSuggestionItemClick
              ),
            ]);
          }}
        />
        <div className="search-bar__suggestion-dropdown" id="autocomplete-panel" />
      </div>
    </div>
  );
};

export const SearchWithCategoryWrapper = () => (
  <SearchContextParent>
    <SearchWithCategory />
  </SearchContextParent>
);

export const SearchWithCategoryPortal = () => {
  const showInline = useShouldShowInlineSearch();
  const elem = document.getElementById(showInline ? 'inline-search' : 'meganav-search');

  return elem ? createPortal(<SearchWithCategory isInline={showInline} />, elem) : null;
};

const container = document.getElementById('search-container');
if (container) {
  const root = createRoot(container);
  root.render(
    <SearchContextParent>
      <SearchWithCategoryPortal />
    </SearchContextParent>
  );
}
