import {useCallback, useEffect, useState} from 'react';
import {
  smallAndUpQuery,
  mediumAndUpQuery,
  largishAndUpQuery,
  largeAndUpQuery,
  extraLargeAndUpQuery,
} from '~sass/pb_styleguide/base/_exports.sass';

export const useMatchQuery = (query, listeners = []) => {
  const windowSizeQuery = window.matchMedia(query);
  const [matches, setMatches] = useState(windowSizeQuery.matches);

  const handleWindowResize = useCallback((sizeQuery) => {
    setMatches(sizeQuery?.matches);
  }, []);

  listeners.push(handleWindowResize);

  // support older browser versions
  windowSizeQuery.addEventListener ||= (_, action) => windowSizeQuery.addListener(action);
  windowSizeQuery.removeEventListener ||= (_, action) => windowSizeQuery.removeListener(action);

  useEffect(() => {
    listeners.forEach((listener) => {
      listener(windowSizeQuery);
      windowSizeQuery.addEventListener('change', listener);
    });
    return () =>
      listeners.forEach((listener) => windowSizeQuery.removeEventListener('change', listener));
  }, []);

  return {windowSizeQuery, matches};
};

const useIsMatchQuery = (query) => {
  const {matches} = useMatchQuery(`(${query})`);
  return matches;
};

export const useIsSmallAndUp = () => useIsMatchQuery(smallAndUpQuery);
export const useIsMediumAndUp = () => useIsMatchQuery(mediumAndUpQuery);
export const useIsLargishAndUp = () => useIsMatchQuery(largishAndUpQuery);
export const useIsLargeAndUp = () => useIsMatchQuery(largeAndUpQuery);
export const useIsExtraLargeAndUp = () => useIsMatchQuery(extraLargeAndUpQuery);
