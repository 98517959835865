import {forwardRef, useEffect, useLayoutEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

export const Video = forwardRef(
  (
    {
      src,
      className = '',
      options: {autoPlay = true, loop = true, muted = true, playsInline = true} = {},
      inViewAutoPlay = false,
      style = null,
      reload = false,
      onClick = () => {},
      onCanPlay = () => {},
      onError = () => {},
      'data-qa-id': dataQaId = null,
      id = null,
      width,
      height,
      mimeType = null,
    },
    videoRef
  ) => {
    const srcArray = Array.isArray(src) ? src : [src];
    const [inView, setInView] = useState(false);
    // eslint-disable-next-line no-param-reassign
    if (!videoRef) videoRef = useRef(null);
    useEffect(() => {
      if (videoRef?.current && reload) {
        videoRef.current.load();
      }
    }, [srcArray]);

    useEffect(() => {
      if (videoRef?.current) {
        // eslint-disable-next-line no-param-reassign
        videoRef.current.defaultMuted = muted;
        videoRef.current.setAttribute('webkit-playsinline', playsInline);
      }
    }, []);

    useLayoutEffect(() => {
      // TODO: This is sufficient for the mobile create flow, but need to be able to control the root
      // element for more use cases, like desktop create.
      if (!inViewAutoPlay || !videoRef?.current) return undefined;
      const element = videoRef.current;
      const callback = (o) => {
        if (o[0].isIntersecting) {
          setInView(true);
          videoRef.current.play();
        }
      };
      const observer = new IntersectionObserver(callback, {
        root: null,
        threshold: 0,
        rootMargin: '50px',
      });
      observer.observe(element);
      return () => observer.unobserve(element);
    }, [inViewAutoPlay]);

    return (
      /* eslint-disable-next-line jsx-a11y/media-has-caption */
      <video
        preload="auto"
        ref={videoRef}
        className={className}
        autoPlay={autoPlay && (inView || !inViewAutoPlay)}
        loop={loop}
        muted={muted}
        playsInline={playsInline}
        onClick={onClick}
        onCanPlay={onCanPlay}
        onError={onError}
        style={style}
        data-qa-id={dataQaId}
        id={id}
        width={width}
        height={height}
        key={id || src}
      >
        {srcArray.map((videoSrc) => (
          <source
            key={videoSrc}
            src={videoSrc}
            type={mimeType || `video/${videoSrc.substring(videoSrc.lastIndexOf('.') + 1)}`}
          />
        ))}
        Your browser does not support the video tag.
      </video>
    );
  }
);

Video.propTypes = {
  src: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
  className: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.shape({
      autoPlay: PropTypes.bool,
      loop: PropTypes.bool,
      muted: PropTypes.bool,
      playsInline: PropTypes.bool,
    }),
    PropTypes.oneOf([null]),
  ]),
  inViewAutoPlay: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  reload: PropTypes.bool,
  onClick: PropTypes.func,
  onCanPlay: PropTypes.func,
  onError: PropTypes.func,
  mimeType: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
};
